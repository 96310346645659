import './RequestView.css';
import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';
import { observer } from 'mobx-react';
import moment from 'moment';
import Button from '../../common/Button';
import Dialog from '@common/Dialog';
import ListColumn from '@common/ListColumn';
import AttachFile from '@common/AttachFile';

import Actions from '@data/Actions';
import Constants from '@data/Constants';
import CommonForm from '@common/CommonForm2';
 

import { generateApproversTooltip } from './utils/approversTooltip';
import { applyTimezoneOnHTML } from '../../common/Timezone';

const crypto = require('crypto');

var editvalor = '';
let companiesOptions = [];
var id_company_selected = 0;
@observer
export default class RequestView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogUser: false,
      company: '',
      companyAutocomplete: '',
      open: false,
      valuesedit: '',
      role: '',
      reset: false,
      user: [],
      perfil: [],
      openContract: false,
      companies: [],
      modaltech:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.handlePages = this.handlePages.bind(this);
  }

  changeCompany = (evt) => {
    this.setState({ company: evt.target.value });
  };

  onStatusChange(toChange) {
    toChange.status = !toChange.status;
    this.props.onFormSubmit(toChange);
  }

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ search: value });
  };

  handlePages(page, edit) {
    editvalor = edit;
    this.props.toggleModal(true);
    this.setState({ valuesedit: edit, role: editvalor.role });
  }

  getRole = () => {
    return this.props.role;
  };

  decryp = (aMsg = '') => {
    try {
      var aSecret = 'Orch3str0!@forever!';

      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(
        (aMsg || '').replace(/\s/g, '+'),
        'base64',
        'utf8'
      );
      tRet += decipher.final('utf8');

      return tRet;
    } catch (e) {
      return {};
    }
  };
  changeCompany = (evt) => {
    this.setState({ company: evt.target.value });
  };
  sendPassword = (values) => {
    if (pass.validaPass(values.password, this.store)) {
      let data = {
        id_user: this.state.user.id_user,
        new_pass: values.password,
        notpass: true,
      };

      this.action.execute(
        'post',
        this.APIRoot + '/redefine',
        '',
        data,
        null,
        this.store.language.CHANGE_PASSWORD_SUCCESS,
        true
      );
      this.store.toggleAlert(true, 'Senha Alterada', 'success');

      this.setState({ reset: false });
    }
  };

  
  onChangeField = (field, value) => {
    if (field.name === 'role') {
      try {
        editvalor.role = value;
      } catch (e) {}

      this.setState({
        role: value,
      });
    } else if (field.name === 'type') {
      try {
        editvalor.type = value;
      } catch (e) {}
      this.setState({
        type: value,
      });
    } else if (field.name === 'id_company') {
      try {
        editvalor.id_company = value;
      } catch (e) {}
      this.setState({
        company: value,
      });

      id_company_selected = value;

      if (this.props.role == 'sponsor') {
        let filterCompayTer = companiesOptions.filter(
          (co) => co.value == value
        );

        if (filterCompayTer[0].type == 2) {
          this.setState({ removeDepart: true });
        } else {
          this.setState({ removeDepart: false });
        }
      }
    }
  };

  componentWillMount() {
    const PAGE = window.location.pathname;
    let role = this.getRole();
    const action = new Actions();
    let typeCompany = role === 'out' ? 2 : 1;
    if (window.location.href.indexOf('?') > -1) {
      this.setState({
        email: window.location.href.split('?')[1],
        GeneralUserListModal: true,
      });
    }

    action
      .execute('post', Constants.APIEndpoints.PERFIL + `getperfils`, 'perfil')
      .then((result) => {
        this.setState({ perfil: result.data });
      });
    action.execute(
      'get',
      Constants.APIRoot + '/api/generalparameters',
      'general_parameters'
    );

    action.execute('get', Constants.APIEndpoints.COMPANY, '').then((item) => {
      this.setState({ companies: item.data });
    });

    if (PAGE == '/dash/creategeneraluser') {
      action.execute('get', Constants.APIEndpoints.DEPARTMENT, 'departments');
    }
  }

  onFormUserSubmit = (values,id) => {
    const action = new Actions();
    
    if(values.fk_id_tech_new >0){

     
      action
      .execute('post', Constants.APIEndpoints.REQUEST + '/'+id, '',values)
      .then((res) => {
          
        
            window.location.reload();
            
        
       });

    }
    else{ 
    let method = values.hasOwnProperty('id_user') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_user')
      ? Constants.APIEndpoints.USER + '/' + values.id_user
      : Constants.APIEndpoints.USER;
    values.url = window.location.origin;
    action
      .execute(method, url, 'users', values, 'id_user')
      .then((e) => {
        this.redirect2('/dash/gsd');
        // this.store.users.map(obj =>e.data.id_user== obj.id_user || obj);
        // this.setState({ GeneralUserListModal: false });
        //  this.redirect('/dash/configuration/users')
      })
      .catch((e) => {
        if (e.data.code == 'E409')
          this.store.toggleAlert(
            true,
            `Falha ao ${
              method == 'post' ? 'cadastrar' : 'editar'
            } usuário: email já cadastrado`,
            'error'
          );
      });

    //      this.setState({openDialogUser : false})
    location.reload();
   }
  };

  render() {
    function decryp(aMsg = '') {
      try {
        var aSecret = 'Orch3str0!@forever!';
        aMsg = aMsg.replace(/\s/g, '');
        let decipher, tRet;
        decipher = crypto.createDecipher('aes-256-cbc', aSecret);
        tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
        tRet += decipher.final('utf8');
        return tRet;
      } catch (e) {
        return {};
      }
    }

    let {
      language,
      handlePage,
      catHandle,
      request,
      dash,
      ondonwFile,
      redirect2,
      store,
      open = true,
      onClose,
      version,
      companies,
      
    } = this.props;
    let { page, values } = this.state;

    const toggleModal = (open) => {
      this.setState({ valuesedit: '', reset: false, openDialogUser: true });
      editvalor = [];
    };

    let perfilList = [];
    perfilList.push({
      value: 0,
      label: 'Nenhum',
    });
    this.state.perfil.map((obj) =>
      perfilList.push({
        value: obj.perfil_id,
        label: obj.name,
      })
    );

    let typeOptions = [
      { value: 1, label: language.CLIENT },
      { value: 2, label: language.INTERNAL },
      { value: 3, label: language.OUTSOURCED },
    ];

    let { role } = this.state;
    let csvData = [];

    let company =
      editvalor.id_company != undefined
        ? editvalor.id_company
        : id_company_selected;
    let type = values ? values.type : this.state.type;

    companiesOptions = this.state.companies.map((obj) => ({
      value: obj.id_company,
      label: obj.name,
      type: obj.type,
    }));

    let departmentOptions = [];

    if (company) {
      let companies = this.state.companies.filter(
        (c) => c.id_company == company
      );

      // if (companies.length) {
      //   companies[0].department.map((obj) => {
      //     departmentOptions.push({
      //       value: obj.id_department,
      //       label: obj.name,
      //     });
      //     if (obj.sub_dep.length) {
      //       obj.sub_dep.map((sd) => {
      //         departmentOptions.push({
      //           value: sd.id_department,
      //           label: sd.name,
      //         });
      //       });
      //     }
      //   });
      // }
    }

    let fields = [
      {
        col: 3,
        name: 'role',
        label: language.ACCESS,
        startIcon: 'fas fa-users-cog',
        type: 'select', //select
        options: [
          { value: 'tech', label: language.TECH },
          { value: 'solicitant', label: language.SOLICITANT },
          { value: 'client', label: language.ADMIN },
        ],
        required: true,
      },
      {
        col: 3,
        name: 'name',
        label: language.NAME,
        startIcon: 'fas fa-signature',
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'hour_value',
        label: language.HOUR_VALUE,
        type: 'number',
      },
      {
        col: 3,
        name: 'email',
        label: 'Email',
        startIcon: 'fas fa-envelope',
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'userad',
        label: language.AD_USER,
        type: 'text',
        required: false,
      },
      {
        col: 3,
        name: 'phone',
        label: language.PHONE,
        type: 'text',
        format: '(##) #####-####',
        placeholder: '(00)00000-0000',
        startIcon: 'fas fa-phone',
        required: true,
      },
      {
        col: 3,
        name: language.TELEPHONE_EXTENSION,
        label: 'ramal',
        type: 'text',
        required: false,
      },
      {
        col: 3,
        // name: 'id_company',
        // label: language.COMPANY,
        // type: 'select',
        // required: true,
        // options: companiesOptions,

        type: 'autocomplete',
        name: 'id_company',
        label: language.COMPANY,
        method: 'POST',
        route: `${Constants.APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        textinit: values && values.company ? values.company : 'Empresa',
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: values ? values.fk_id_company : 0,
        routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
        required: true,
        // onchance
      },
    ];

    if (role === 'out') {
      fields.push();
    } else if (role === 'solicitant' || role === 'sponsor') {
      if (role === 'sponsor' && this.props.values && this.props.values.sponsor)
        this.props.values.role = 'sponsor';
      fields.push({
        col: 3,
        name: 'registration',
        label: language.REGISTRATION,
        type: 'text',
        required: false,
      });

      if (!this.state.removeDepart) {
        fields.push({
          col: 3,
          // name: 'fk_id_department',
          // label: language.DEPARTMENT,
          // type: 'select',
          // required: true,
          // options: departmentOptions,

          type: 'autocomplete',
          name: 'fk_id_department',
          label: language.DEPARTMENTS,
          textlabel: language.DEPARTMENTS,
          method: 'POST',
          route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
          routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
          fieldquery: 'search',
          textinit:
            values && values.departments_labels
              ? values.departments_labels
              : language.DEVICES,
          fieldvaluedb: 'id_department',
          fieldlabeldb: 'name',
          idinit: values && values.departments ? values.departments : 0,
          routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
          filterAll: { fk_id_company: company || 0 },
          methodGetAll: 'POST',
          required: true,
          showEmail: false,
        });
      }
    } else if (role === 'tech') {
      fields.push({
        col: 3,
        name: 'type',
        label: language.TYPE,
        type: 'select',
        required: true,
        options: typeOptions,
        disabled: !!(values && values.type),
      });

      if (type === 1 || type === 3) {
      }
      fields.push({
        col: 3,
        // name: 'fk_id_department',
        // label: language.DEPARTMENT,
        // type: 'select',
        // required: true,
        // options: departmentOptions,

        type: 'autocomplete',
        name: 'fk_id_department',
        label: language.DEPARTMENTS,
        textlabel: language.DEPARTMENTS,
        method: 'POST',
        route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
        routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
        fieldquery: 'search',
        textinit:
          values && values.departments_labels
            ? values.departments_labels
            : language.DEVICES,
        fieldvaluedb: 'id_department',
        fieldlabeldb: 'name',
        idinit: values && values.departments ? values.departments : 0,
        routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
        filterAll: { fk_id_company: company || 0 },
        methodGetAll: 'POST',
        required: true,
        showEmail: false,
      });
    } else if (role === 'client') {
      let companies = this.state.companies.filter((c) => c.id_company == 1);
      if (companies.length) {
      }

      fields.push({
        col: 3,
        // name: 'fk_id_department',
        // label: language.DEPARTMENT,
        // type: 'select',
        // required: true,
        // options: departmentOptions,

        type: 'autocomplete',
        name: 'fk_id_department',
        label: language.DEPARTMENTS,
        textlabel: language.DEPARTMENTS,
        method: 'POST',
        route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
        routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
        fieldquery: 'search',
        textinit:
          values && values.departments_labels
            ? values.departments_labels
            : language.DEVICES,
        fieldvaluedb: 'id_department',
        fieldlabeldb: 'name',
        idinit: values && values.departments ? values.departments : 0,
        routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
        filterAll: { fk_id_company: company || 0 },
        methodGetAll: 'POST',
        required: true,
        showEmail: false,
      });
    }
    fields.push({
      col: 3,
      name: 'fk_id_perfil',
      label: language.PROFILE,
      type: 'select',
      required: false,
      options: perfilList,
    });

    let col = [
      {
        label: '',
        key: 'check',
        style: { width: 1, paddingRight: 0, paddingLeft: 0 },
        thConfig: true,
        search: <i></i>,
        //   <Checkbox className='-ml-1' onChange={(evt) => this.checkBoxSelectAll(evt.target.checked)} />
      },
      { label: language.DATE, key: 'dt_cad', style: { width: 100 } },
      {
        label: language.HOUR,
        key: 'dt_hr',
        style: { width: 1, paddingLeft: 0 },
        thConfig: true,
      },
      {
        label: 'ID',
        key: 'id',
        style: { paddingRight: 0, width: 50, textAlign: 'left' },
        thConfig: true,
      },
      {
        label: language.ACTION,
        key: 'action',
        style: { width: 50 },
        thConfig: true,
      },

      {
        label: language.COMPANY,
        key: 'company',
        style: { width: 200, paddingRight: 0 },
        thConfig: true,
      },
      { label: language.USER, key: 'user', style: { width: 100 } },
      {
        label: language.SUBJECT,
        key: 'subject',
        style: { width: 400, paddingRight: 0, paddingLeft: 0 },
        thConfig: true,
      },
      {
        label: language.GROUP_TECH,
        key: 'grouptech',
        style: { width: 350 },
        thConfig: true,
      },
      {
        label: language.SERVICE_CATALOG,
        key: 'service',
        style: { width: 400, paddingRight: 0, paddingLeft: 0 },
        thConfig: true,
      },

      {
        label: language.SLA,
        key: 'sla',
        style: { width: 70, paddingRight: 0 },
        thConfig: true,
      },
      {
        label: language.SLA_TIME,
        key: 'time',
        style: { width: 200 },
        thConfig: true,
      },
      {
        label: language.APPROVER,
        key: 'aprovador',
        style: { width: 200 },
        thConfig: true,
      },
    ];

    if (!!this.state.companyId) {
      requests = requests.filter(
        (c) => c.fk_id_company === this.state.companyId
      );
    }

    if (this.props.approve == true) {
      requests = requests.filter((c) => c.approval === true);
    }

    //let departmentNames = departments.map(dpt => dpt.name);

    let back = (
      <Button
        variant="circle"
        icon="fas fa-arrow-left"
        color="primaryGradient"
        label={language.BACK}
        onClick={() => redirect2('/dash/request')}
      />
    );

    if (dash) {
      back = <i></i>;
    }
    if (request) {
    } else {
      request = store.requests.filter(
        (g) => g.fk_id_request == request.fk_id_request
      )[0];
    }
    let stt = null;
    let br = sessionStorage.getItem('sensr_lang') == 'PT_BR';
    if (
      sessionStorage.getItem('sensr_lang') == 'PT_BR' ||
      sessionStorage.getItem('sensr_lang') == null
    ) {
      if (request) {
        if (request.status == 'In Progress') {
          stt = language.TICKET_STATUS.IN_PROGRESS;
        } else if (request.status == 'Resolved') {
          stt = language.TICKET_STATUS.RESOLVED;
        } else if (request.status == 'Reopened') {
          stt = language.TICKET_STATUS.REOPENED;
        } else if (request.status == 'Approve') {
          stt = language.TICKET_STATUS.IN_APPROVAL;
        } else if (request.status == 'Approved') {
          stt = language.TICKET_STATUS.APPROVED;
        } else if (request.status == 'Open') {
          stt = language.TICKET_STATUS.OPEN;
        } else if (request.status == 'Closed') {
          stt = language.TICKET_STATUS.CLOSED;
        } else if (request.status == 'Cancelled') {
          stt = language.TICKET_STATUS.CANCELLED;
        } else if (request.status == 'Reject') {
          stt = language.TICKET_STATUS.REJECTED;
        }
      }
    } else {
      stt = request.status;
    }

    //  store.catalogs.filter(n=>n.id_catalog_service === )
    let category = request.namecategory_;
    let service = request.catalogservicename_;
    let terceironivel = request.catalogtaskname_;

    let isApproval = false;
    if (!!request && request.approval) {
      isApproval = true;
    }

    let subjectFormat =
      request.subject.length > 50
        ? `${request.subject.substring(0, 50)}...`
        : request.subject;
    let users = [];
    let aprovadores = '';

    if (request.chiefname != null) {
      aprovadores = request.chiefname;
    }
    return (
      <Dialog
        PaperProps={{
          id: 'RequestView',
        }}
        open={open}
        onClose={onClose}
        title={
          <div className="w-100 bar" style={{ zoom: 0.8 }} zoom={0.8}>
            <Tooltip
              html={<span>{request.subject}</span>}
              arrow={true}
              position="right"
              theme="dark"
            >
              <span className="subject_fixed ellipsis">{subjectFormat}</span>
            </Tooltip>
          </div>
        }
      >
        <div className="content" style={{ padding: 10 }}>
          <ListColumn
            col={6}
            items={[
              {
                label: language.STATUS,
                value: stt,
              },
              isApproval
                ? {
                    label: language.APPROVER,
                    value: 
                    <div>{aprovadores}<b> {(store.getUserLoged().role=="client"||store.getUserLoged().role=="tech") &&
                    store.general_parameters.edit_approver_categorization&& 
                     this.props.role!= 'solicitant'&&<Button
                    variant="circle"
                    icon="fas fa-pencil-alt"
                    color="success"
                    outlined
                    label={language.CHANGE_TECH}
                    onClick={() => this.setState({modaltech:true})}
                    style={{ border: 'none', fontSize: 14 }}
                  />}  </b></div>,
                  }

                  
                : null,
              version
                ? {
                    label: 'Ticket',
                    value: version,
                  }
                : null,
              {
                label: language.DATE_CAD,
                value: moment(request.dt_cad).format('L'),
              },
              {
                label: language.REGISTER_HOUR,
                value: moment(request.dt_cad).format('LTS'),
              },
              {
                label: language.COMPANY,
                value: request.name_company_fk || request.name_company,
              },

              {
                label: language.SOLICITANT,
                value:
                  request.namerequest == null
                    ? request.name
                    : request.namerequest,
              },
              {
                label: language.EMAIL,
                value:
                  request.emailrequest == null
                    ? request.username
                    : request.emailrequest,
              },

              {
                label: language.DEPARTMENT,
                value: request.department,
              },

              {
                label: language.ID_ACESS_REMOTE,
                value: request.id_access_remote,
              },
              {
                label: language.PHONE,
                value: request.phone,
              },
              {
                label: language.TELEPHONE_EXTENSION,
                value: request.ramal,
              },
              {
                label: language.CATEGORY,
                value: category,
              },
              {
                label: language.SUB_CATEGORY,
                value: service,
              },
              {
                label: language.THIRDED_LEVEL,
                value: terceironivel,
              },
              {
                label: language.USER_WHO_REJECTED,
                visible: request.status == 'Reject',
                value: request.user_up,
              },
              {
                col: 12,
                label: language.DESCRIPTION,
                value: (
                  <p
                    style={{ color: '#888080' }}
                    dangerouslySetInnerHTML={{
                      __html: request.description,
                    }}
                  />
                ),
              },
              {
                col: 12,
                label: language.ATTACHMENT,
                value: (
                  <ul className="my-0" style={{ zoom: 0.8 }}>
                    {request.files != undefined && request.files != null
                      ? request.files.map((f) => (
                          <li className="py-1">
                            <AttachFile
                              label={
                                String(f).split('|')[0] +
                                ' - ' +
                                moment(String(f).split('|')[3]).format('L')
                              }
                              onClick={() =>
                                ondonwFile(
                                  String(f).split('|')[1],
                                  f.split('|')[0]
                                )
                              }
                              // onDelete={() => this.props.ondeletefile(String(f))}
                            />
                          </li>
                        ))
                      : null}
                  </ul>
                ),
              },
              {
                col: 12,

                visible:
                  request.status == 'Cancelled' || request.status == 'Reject',
                label: language.REASON,
                value: (
                  <p
                    style={{ color: '#888080' }}
                    dangerouslySetInnerHTML={{
                      __html: request.reason,
                    }}
                  />
                ),
              },
            ].filter(Boolean)}
          />
        </div>

        <div>
          {request.template != null && request.template.length > 5 ? (
            <div className="template template-dinamic">
              <p
                style={{ color: '#888080', pointerEvents: 'none' }}
                dangerouslySetInnerHTML={{ __html: applyTimezoneOnHTML(decryp(request.template)) }}
              />
            </div>
          ) : null}
        </div>
        {request.fk_id_user > 0 && request.status != 'Reject' ? (
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-2 w-50">
              <Button
                icon="fas fa-thumbs-down"
                label={
                  this.props.solicit === false
                    ? language.REJECT
                    : language.CANCEL
                }
                color="danger"
                fluid
                variant="normal"
                style={{
                  height: 40,
                }}
                onClick={() =>
                  handlePage('reject', {
                    previousPage: 'view',
                    id_request: request.id_request,
                    request: request,
                  })
                }
              />
            </div>
            <div className="px-2 w-50">
              <Button
                icon="fas fa-tags"
                label={language.CATEGORIZE}
                color="primary"
                fluid
                variant="normal"
                style={{
                  height: 40,
                }}
                disabled={
                  !!this.props.solicit ||
                  store.getUserLoged().perfil.gsd_categorization == false
                }
                onClick={() =>
                  request.status === null
                    ? store.toggleAlert(
                        true,
                        language.UNREGISTERED_USER,
                        'error'
                      )
                    : request.status === 'Approve'
                    ? store.toggleAlert(
                        true,
                        language.APPROVAL_REQUIRED,
                        'error'
                      )
                    : catHandle({
                        subject: request.subject,
                        description: request.description,
                        fk_id_user: request.id_user,
                        fk_id_request: request.id_request,
                        fk_id_company:
                          request.fk_id_company_fk > 0
                            ? request.fk_id_company_fk
                            : request.fk_id_company,
                        fk_id_category: request.fk_id_category,
                        fk_id_catalog_service: request.fk_id_catalog_service,
                        fk_id_catalog_task: request.fk_id_catalog_task,
                        fk_id_sla: request.fk_id_sla,
                        html_values: request.html_values,
                        template:
                          request.template !== null ? request.template : null,
                        ticketArray: [],
                        req: [],
                        fk_id_device: request.fk_id_device,
                        fk_id_contract: request.fk_id_contract,
                        files: request.files,
                        name_company:
                          request.name_company_fk || request.name_company,
                        solicitant: request.name,
                        department: request.department,
                        type_ticket_cat: request.type_ticket_cat,
                        language: language,
                      })
                }
              />
            </div>
            <div className="px-2 w-50">
              <Button
                icon="fas fa-tags"
                label={`${language.CATEGORIZE} ${language.AND} ${language.TO_MEET}`}
                color="primary"
                fluid
                variant="normal"
                style={{
                  height: 40,
                }}
                disabled={
                  !!this.props.solicit ||
                  store.getUserLoged().perfil.gsd_categorization == false
                }
                onClick={() =>
                  request.status === null
                    ? store.toggleAlert(
                        true,
                        language.UNREGISTERED_USER,
                        'error'
                      )
                    : request.status === 'Approve'
                    ? store.toggleAlert(
                        true,
                        language.APPROVAL_REQUIRED,
                        'error'
                      )
                    : catHandle({
                        subject: request.subject,
                        description: request.description,
                        fk_id_user: request.id_user,
                        fk_id_request: request.id_request,
                        fk_id_company:
                          request.fk_id_company_fk > 0
                            ? request.fk_id_company_fk
                            : request.fk_id_company,
                        html_values: request.html_values,
                        fk_id_category: request.fk_id_category,
                        fk_id_catalog_service: request.fk_id_catalog_service,
                        fk_id_catalog_task: request.fk_id_catalog_task,
                        fk_id_sla: request.fk_id_sla,
                        template:
                          request.template !== null ? request.template : null,
                        ticketArray: [],
                        req: [],
                        fk_id_device: request.fk_id_device,
                        fk_id_contract: request.fk_id_contract,
                        files: request.files,
                        name_company:
                          request.name_company_fk || request.name_company,
                        solicitant: request.name,
                        department: request.department,
                        type_ticket_cat: request.type_ticket_cat,
                        start: true,
                      })
                }
              />
            </div>
          </div>
        ) : (
          store.getUserLoged().role == 'client' &&
          request.status != 'Reject' && (
            <div className="px-2 w-50">
              <Button
                icon="fas fa-thumbs-down"
                label={language.ADD_USER}
                color="warning"
                fluid
                variant="normal"
                style={{
                  height: 40,
                }}
                onClick={() => toggleModal(true)}
              />
            </div>
          )
        )}

        <Dialog
          style={{ height: '250px' }}
          title={language.GENERAL_USER_CREATION}
          open={this.state.openDialogUser}
          onClose={() => this.setState({ openDialogUser: false })}
          maxWidth="lg"
          monitor_scroll
        >
          <CommonForm
            fields={fields}
            values={editvalor.cnpj != undefined ? editvalor : values}
            onChangeField={this.onChangeField}
            onSubmit={(values) => this.onFormUserSubmit(values)}
            button={{
              label: language.SAVE,
            }}
          />
        </Dialog>

        <Dialog
                maxWidth="xs"
                title={language.CHANGE_TECHNICIAN}
                monitor_scroll
                modal={false}
                open={this.state.modaltech}
                onClose={() => {
                 this.setState({modaltech:false})
                }}
              >
                <div>
                  <CommonForm
                    language={language}
                    fields={[
                      {
                        name: 'id_request',
                        label: language.TICKET,
                        type: 'text',
                        visible: false,
                        disabled: true,
                      },
                      {
                        span: 4,
                        type: 'autocomplete',
                        name: 'fk_id_tech_new',
                        label: language.TECH,
                        className: 'tech-group-ticket',
                        required: true,
                        method: 'POST',
                        route: `${Constants.APIEndpoints.USER}/filter`,
                        routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                        filterAll: {
                           all_fk_id_company: request.fk_id_company|| 0,
                        },
                        fieldquery: 'search',
                        textinit:'',
                        fieldvaluedb: 'id_user',
                        fieldlabeldb: 'name',
                        idinit: '',
                        routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                        detail: true,
                        methodGetAll: 'POST',
                      },
                      {
                        name: 'justify',
                        label: language.REASON,
                        type: 'textEditor',
                        required:true
                       
                      },

                    ]}
                    onSubmit={(v) => {
                     this.onFormUserSubmit(v, request.id_request);
                      this.setState({modaltech:false})
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              </Dialog>

      </Dialog>
    );
  }
}
