export const perfils = {
   positions_users: false,
   password_vault: false,
   gsd_answer: false,
   gsd_dash_ge: false,
   gestao_ticket: false,
   task_dashboard: false,
   task_list: false,
   task_approve: false,
   task_calendar: false,
   change_management_workflow: false,
   change_management_group: false,
   change_template: false,
   risk_change: false,
   grc_iso_9001: false,
   grc_iso_37001: false,
   sprint: false,
   gp_template: false,
   gi_snmp: false,
   central_help: false,
   gsd_cmdb: false,
   channel_management: false,
   coin_management: false,
   cio_cio_cockpit: false,
   cio_panel: false,
   cio_bsc: false,
   cio_canvas: false,
   gcf_dasboard: false,
   gcf_service_order: false,
   gcf_audit: false,
   gcf_assessment: false,
   gcf_it_budget_control: false,
   gi_dashboard: false,
   gi_monitor: false,
   gi_agent_download: false,
   gi_capacity_plain: false,
   gsd_dashboard: false,
   gsd_dm: false,
   gsd_categorization: false,
   gsd_request_management: false,
   gsd_incident_management: false,
   gsd_problem_management: false,
   gsd_change_management: false,
   gsd_stock_management: false,
   gsd_inventory: false,
   gsd_knowledge_base: false,
   gsd_consult_historic: false,
   grc_dashboard: false,
   grc_side_dish: false,
   grc_iso_27001: false,
   grc_iso_20000: false,
   grc_cobit: false,
   grc_backup_management: false,
   grc_it_evolution_management: false,
   grc_lgpd: false,
   grc_risk_matrix: false,
   gp_panel_pmo: false,
   gp_projects: false,
   gp_allocation: false,
   gp_object_priorization: false,
   report_tech: false,
   report_group_tech: false,
   company_company_list: false,
   company_department: false,
   company_subdepartment: false,
   company_mail_policy: false,
   company_company_hour: false,
   company_ldpa: false,
   company_company_logo: false,
   gi_device: false,
   gi_device_type: false,
   gi_outsourced: false,
   gi_discovery: false,
   gcf_contract: false,
   gcf_sponsor: false,
   gcf_sla_policy: false,
   gcf_sla_policy_allowance: false,
   gsd_task: false,
   gsd_service_catalog: false,
   gsd_category: false,
   gsd_changerisk: false,
   gsd_requester: false,
   gsd_tech: false,
   gsd_group: false,
   gsd_is_change_manager: false,
   gsd_template: false,
   gsd_slaparam: false,
   gsd_workday: false,
   perfil: false,
   user_create: false,
   parameters: false,
   notifications_center: false,
   password_questions: false,
   automation: false,
   chief_alt: false,
   fale: false,
   location : false,
   resolution_category : false,
   resolution_code : false,
   outage : false,
   ic_relationship : false,
   cab_events : false,
   approvals : false,
   edit_ticket_requester: false,
   file_extensions: false,
   sprint_activity_effort: false
 };