import React, { Component } from 'react';

import { Tooltip } from 'react-tippy';
import { observer } from 'mobx-react';
import { TextField, MenuItem, Paper } from '@material-ui/core';
import { Card, Chip } from '@material-ui/core';
import RaisedButton from '@common/RaisedButton';
import PageLayout from '@common/PageLayout';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import Store from '@data/Store';
import moment from 'moment';

@observer
export default class PerfilList extends Component {

  constructor() {
    super();
    this.state = {
      company: ''
    };
  }

  changeCompany = (evt) => {
    this.setState({ company: evt.target.value });
  }

  render() {
    let { language, companies, handlePage, APIEndpoints ,setcheck   } = this.props;
    let listCompanies = companies.map(c => (
      <MenuItem key={c.id_compcany} value={c.id_company}>
        {c.name}
      </MenuItem>
    ));

    let col = [
      { key: 'id', label: 'Id', style: { width: 30, textAlign: 'left' } },
      { key: 'name', label: language.NAME, style: { width: 140, textAlign: 'left' } },
      { key: 'dt_cad', label: language.DATE_CAD, style: { width: 30, textAlign: 'left' } },
      { key: 'action', label: language.ACTIONS, style: { width: 30, textAlign: 'left' } },
    ];
    
    let data = this.props.perfil.map(g => {
      return {
        id: g.perfil_id,
        name: g.name,
        dt_cad: moment(g.dt_cad).format('L'),


        action: (
          <React.Fragment>
            <Chip
              className='mx-2 muit-chip'
              label={
                <div className='d-flex align-items-center justify-content-between'>

                  <Button
                    variant='circle'
                    icon='fas fa-edit'
                    label={language.EDIT}
                    color='default'
                    style={{ fontSize: 12 }}
                    size={.7}
                    className='mr-2'
                    onClick={() =>{ handlePage('form', g) ; setcheck(false)}}

                  />
                  <Button
                    variant='circle'
                    icon='far fa-trash-alt'
                    label={language.DELETE}
                    color='danger'
                    style={{ fontSize: 13 }}
                    size={.7}
                    onClick={() => handlePage('remove', g)}
                    loading={Store.$btn_loading('delete-' + APIEndpoints.PERFIL + `deleteperfil/${g.perfil_id}`)}
                  />

                </div>
              }
            />
            {/*<Row style={{margin:0}}>
              <Col md={4}>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.EDIT}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    className="editIcon pointer"
                    style={{ width: 25, height: 25 }}
                    onClick={() => handlePage('form', obj)}
                  />
                </Tooltip>
              </Col>
              <Col md={4}>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.REMOVE}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    className="trashIcon pointer"
                    style={{ width: 25, height: 25 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </Tooltip>
              </Col>

              <Col md={4}>
                <Tooltip html={<div style={{ color: '#fff', fontSize: 15 }}><strong>{language.REMOVE}</strong></div>} position="right" theme="dark">
                  <div className="addIcon pointer"style={{ width: 25, height: 25 }} onClick={() => handlePage('config', obj)} />
                </Tooltip>
              </Col>
            </Row>*/}
          </React.Fragment>
        )
      }
    })
    let { configurationProps = {} } = this.props;
    return (

      <div id="PerfilList" className='pb-3 w-100'>
        <CommonTable
          paginationTop={false}
          searchColumn={true}
          beforeBar2={<i></i>
            /*<TextField
              
              select
              label={language.COMPANY}
              style={{ minWidth:200, zoom:.8}}
              value={this.state.company}
              onChange={this.changeCompany}
              margin="dense"
              variant="outlined"
              className="input-min"
              SelectProps={{
                className: 'select-min'
              }}
            >
              {listCompanies}
            </TextField> */
          }
          beforeBar={
            <RaisedButton
              circleButton
              icon='fas fa-plus'
              style={{ zoom: .8 }}
              zoom={.8}
              color='primary'
              className='mr-3'
              label={language.ADD + ' Perfil'}
              onClick={() => handlePage('form')}
            />
          }
          col={col}
          data={data}
          index='PerfilList'
          rowsPerPageOptions={[100, 250]}
          searchColumn orderColumn
        />
      </div>

    );
  }
}