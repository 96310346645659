import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';

import Button from '@common/Button';
import Dialog from '@common/Dialog';
import Header from '@common/Header';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import DialogConfirm from '@common/DialogConfirm';

import { Actions, Store, Constants } from '@data';

const action = new Actions();

import NotificationForm from './notificationForm';
import RecipientsForm from './recipientsForm';

import './index.css';

const defaultPageDelete = { open: false, values: {} };

const index = () => {
  const { language } = Store;
  const [page, setPage] = useState('list');
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [pageDelete, setPageDelete] = useState(defaultPageDelete);

  const loadNotification = () => {
    action
      .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/rule`, '')
      .then((res) => {
        setNotificationsList(res.data.data);
      }).catch((error) => {
        console.log(error);
      })
  };

  useEffect(() => {
    loadNotification();
  }, []);

  const handlePageDelete = (data) => {
    setPageDelete(data);
  };

  const deleteNotification = () => {
    Store.spinner = true;
    action
      .execute(
        'delete',
        `${Constants.APIEndpoints.NOTIFICATION}/rule/${pageDelete.values.id}`,
        ''
      )
      .then(() => {
        loadNotification();
        handlePageDelete(defaultPageDelete);
        Store.spinner = false;
        Store.toggleAlert(
          true,
          language.REGISTER_DELETED,
          'success'
        );
      })
      .catch(() => {
        Store.toggleAlert(
          true,
          language.ERROR_REQUEST,
          'error'
        );
        Store.spinner = false;
      });
  }

  let col = [
    {
      key: 'name',
      label: language.NAME,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
    },
    {
      key: 'description',
      label: language.DESCRIPTION,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
      search: false
    },
  ];

  const data = notificationsList.map((item) => ({
    name: item.name,
    description: item.description,
    action: (
      <React.Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              {/* <Button
                variant="circle"
                icon="fas fa-envelope"
                label={language.RECIPIENTS}
                color="primary"
                style={{
                  fontSize: 13,
                  width: 21,
                  height: 21,
                  marginRight: 10,
                }}
                onClick={() => {
                  setValue(item);
                  setPage('recipient');
                }}
                size={0.9}
              /> */}
              <Button
                variant="circle"
                icon="fas fa-edit"
                label={language.EDIT}
                color="default"
                style={{ fontSize: 13 }}
                size={0.7}
                className="mr-2"
                onClick={() => {
                  setValue(item);
                  setPage('condition');
                }}
              />
              <Button
                variant="circle"
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                style={{ fontSize: 14 }}
                size={0.7}
                onClick={() => handlePageDelete({ open: true, values: item })}
              />
            </div>
          }
        />
      </React.Fragment>
    ),
  }));

  return (
    <div>
      <CommonTable
        title=""
        index=""
        data={data}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        beforeBar={
          <RaisedButton
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            key="02"
            circleButton
            icon="fas fa-plus"
            label={language.ADD}
            primary={true}
            onClick={() => {
              setValue(null);
              setPage('add');
            }}
          />
        }
      />
      {page === 'condition' || page === 'add' ? (
        <Dialog
          title={
            page === 'condition'
              ? language.NOTIFICATION_CONDITION
              : language.NOTIFICATION_REGISTRATION
          }
          open={true}
          onClose={() => setPage('list')}
          maxWidth="md"
          miniVersion = {true}
        >
          <NotificationForm
            loadNotification={loadNotification}
            language={language}
            value={value}
            onClose={() => setPage('list')}
          />
        </Dialog>
      ) :
      page === 'recipient' ? (
        <Dialog
          title={language.RECIPIENTS}
          open={true}
          onClose={() => setPage('list')}
          maxWidth="md"
          miniVersion = {true}
        >
          <RecipientsForm
            loadNotification={loadNotification}
            language={language}
            value={value}
            onClose={() => setPage('list')}
          />
        </Dialog>
      ) : null }

      {pageDelete.open && (
        <DialogConfirm
          open={pageDelete.open}
          language={language}
          confirmProps={{ color: 'danger' }}
          onConfirm={deleteNotification}
          onClose={() => handlePageDelete(defaultPageDelete)}
          title={language.CONFIRM_DELETE}
          message={pageDelete.values.name}
        />
      )}
    </div>
  );
};

export default index;
