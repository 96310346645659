import React from 'react';
import { Tooltip } from 'react-tippy';
import { MenuItem } from '@material-ui/core'; // controls
import { Checkbox } from '@material-ui/core';
import { observer } from 'mobx-react';
import RequestApproval from './RequestApproval';
import Button from '@common/Button';

import PageLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
import TicketUtils from '../../util/TicketUtils';
import Dialog from '@common/Dialog';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import CommonForm from '@common/CommonForm2';
import Icon from '@material-ui/core/Icon';
import { generateApproversTooltip } from './utils/approversTooltip';
import './RequestList.css';
import CommonPage from '../../common/CommonPage';
import { translateFromPtBR } from '../../common/languages/Dictionary';
var editvalor = '';
let companiesOptions = [];
var id_company_selected = 0;
@observer
export default class RequestList extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      update: 0,
      companyId: '',
      filtered: [],
      ticketsArray: [],
      ticketsApproveToEmail: [],
      search: '',
      openDialogUser: false,

      company: '',
      open: false,
      valuesedit: '',
      role: '',
      reset: false,
      user: [],
      perfil: [],
      openContract: false,
      departmentOptions: [],
      params: [],
    };
    this.updateState = this.updateState.bind(this);
    this.reloadRequests = this.reloadRequests.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.handlePages = this.handlePages.bind(this);
    this.DepartmentList = this.DepartmentList.bind(this);
    this.cleanAllFilters = this.cleanAllFilters.bind(this);
    this.sendEmailToApprovers = this.sendEmailToApprovers.bind(this);
  }

  sendEmailToApprovers() {
    const data = { requests: this.state.ticketsApproveToEmail };
    this.action
      .execute(
        'post',
        this.APIEndpoints.REQUEST + '/sendemailtoapprovers',
        '',
        data
      )
      .then((res) => {
        this.store.toggleAlert(true, 'Emails enviados com sucesso', 'success');
        this.setState({ ticketsApproveToEmail: [] });
      });
  }

  changeCompany = (evt) => {
    this.setState({ company: evt.target.value });
  };

  onStatusChange(toChange) {
    toChange.status = !toChange.status;
    this.props.onFormSubmit(toChange);
  }

  cleanAllFilters() {
    this.props.callPageSelectFilter();
  }

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ search: value });
  };

  handlePages(page, edit) {
    editvalor = edit;
    this.props.toggleModal(true);
    this.setState({ valuesedit: edit, role: editvalor.role });
  }
  componentDidMount() {
    // this.updateState();
    //this.props.callPageSelectFilter();
  }

  getRole = () => {
    return this.props.role;
  };

  decryp = (aMsg = '') => {
    try {
      var crypto = require('crypto');
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  };

  changeCompany = (evt) => {
    this.setState({ company: evt.target.value });
  };

  sendPassword = (values) => {
    if (pass.validaPass(values.password, this.store)) {
      let data = {
        id_user: this.state.user.id_user,
        new_pass: values.password,
        notpass: true,
      };

      this.action.execute(
        'post',
        this.APIRoot + '/redefine',
        '',
        data,
        null,
        this.store.language.CHANGE_PASSWORD_SUCCESS,
        true
      );
      this.store.toggleAlert(
        true,
        this.store.language.CHANGE_PASSWORD_SUCCESS,
        'success'
      );

      this.setState({ reset: false });
    }
  };

  onChangeField = (field, value) => {
    if (field.name === 'role') {
      try {
        editvalor.role = value;
      } catch (e) {}

      this.setState({
        role: value,
      });
    } else if (field.name === 'type') {
      try {
        editvalor.type = value;
      } catch (e) {}
      this.setState({
        type: value,
      });
    } else if (field.name === 'id_company') {
      try {
        editvalor.id_company = value;
      } catch (e) {}
      this.DepartmentList(value);
      this.setState({
        company: value,
      });

      id_company_selected = value;

      if (this.props.role == 'sponsor') {
        let filterCompayTer = companiesOptions.filter(
          (co) => co.value == value
        );

        if (filterCompayTer[0].type == 2) {
          this.setState({ removeDepart: true });
        } else {
          this.setState({ removeDepart: false });
        }
      }
    }
  };

  componentWillMount() {
    const PAGE = window.location.pathname;
    let role = this.getRole();
    const action = new Actions();
    let typeCompany = role === 'out' ? 2 : 1;
    if (window.location.href.indexOf('?') > -1) {
      this.setState({
        email: window.location.href.split('?')[1],
        GeneralUserListModal: true,
      });
    }
    action.execute(
      'get',
      Constants.APIEndpoints.USER +
        '/userchange/' +
        this.store.getUserLoged().id,
      'ad'
    );
    action
      .execute('post', Constants.APIEndpoints.PERFIL + `getperfils`, 'perfil')
      .then((result) => {
        this.setState({ perfil: result.data });
      });

    action.execute('get', Constants.APIEndpoints.COMPANY, '').then((item) => {
      this.setState({ companies: item.data });
    });

    if (PAGE == '/dash/creategeneraluser') {
      action.execute('get', Constants.APIEndpoints.DEPARTMENT, 'departments');
    }
  }

  checkBoxSelectAll(v) {
    if (v) {
      this.setState({
        ticketsArray: this.props.requests
          .filter((v) => v.status != 'Approve')
          .map((e) => e.id_request),
      });
    } else {
      this.setState({ ticketsArray: [] });
    }
  }

  checkBoxSelect(idvalue, fk_id_company, language) {
    let v = true;
    this.props.requests
      .filter((b) => this.state.ticketsArray.includes(b.id_request))
      .map((x) => {
        if (x.fk_id_company_fk != fk_id_company) {
          v = false;
        }
      });

    if (this.state.ticketsArray.indexOf(idvalue) == -1) {
      if (v == false) {
        this.props.toggleAlert(
          true,
          language.CANNOT_BE_ASSOCIATED_DIFFERENT_COMPANIES,
          'error'
        );
      } else {
        const ticketsChecked = this.state.ticketsArray;
        ticketsChecked.push(idvalue);
        this.setState({ ticketArray: ticketsChecked });
      }
    } else {
      const ticketsChecked = this.removeA(this.state.ticketsArray, idvalue);
      this.setState({ ticketArray: ticketsChecked });
    }
  }

  checkApproveToEmail(id_request) {
    if (this.state.ticketsApproveToEmail.indexOf(id_request) == -1) {
      let requests = this.state.ticketsApproveToEmail
      requests.push(id_request);
      this.setState({ticketsApproveToEmail : requests})
    } else {
      let requests = this.removeA(
        this.state.ticketsApproveToEmail,
        id_request
      );
      this.setState({ticketsApproveToEmail : requests})
    }
  }

  onFormUserSubmit = (values, language) => {
    let method = values.hasOwnProperty('id_user') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_user')
      ? this.APIEndpoints.USER + '/' + values.id_user
      : this.APIEndpoints.USER;
    values.url = window.location.origin;
    this.action
      .execute(method, url, 'users', values, 'id_user')
      .then((e) => {
        //    this.redirect2('/dash/configuration/users');
        // this.store.users.map(obj =>e.data.id_user== obj.id_user || obj);
        // this.setState({ GeneralUserListModal: false });
        //  this.redirect('/dash/configuration/users')
      })
      .catch((e) => {
        if (e.data.code == 'E409')
          this.store.toggleAlert(
            true,
            `Falha ao ${
              method == 'post' ? language.TO_REGISTER : language.EDIT
            } ${language.USER}: ${language.EMAIL_ALREADY_REGISTERED}`,
            'error'
          );
      });
    setTimeout(this.redirect('/dash/request'), 2000, this);

    this.setState({ openDialogUser: false });
  };

  removeA(arr) {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }
  updateState() {
    this.setState({ update: ++this.state.update });
    setTimeout(this.updateState, 1000, this);
  }

  reloadRequests() {
    this.props.onReloadRequests();
  }

  handleCompany(values) {
    this.setState({ companyId: values == 0 ? '' : values });
  }

  DepartmentList(id) {
    this.action
      .execute('get', this.APIEndpoints.DEPARTMENT + '/' + id, '')
      .then((v) => {
        let listDepartments = v.data.map((c) => ({
          value: parseInt(c.id_department),
          label: c.name,
        }));
        this.setState({ departmentOptions: listDepartments });
      });
  }

  handleNewRequest() {
    if (this.props.general_params.request_by_portal) {
      this.props.redirect2('/dash/portal');
    } else {
      this.props.handleDialog(1);
    }
  }

  render() {
    let {
      language,
      handlePage,
      redirect,
      request,
      companies,
      requests,
      onFormSubmit,
      onFormSubmitAprove,
      redirect2,
      users,
      catHandle,
      timez,
      mailcontracts,
      pathname,
      view,
      approve,
      downloadRequests,
    } = this.props;
    let { update, ticketsArray } = this.state;
    let c = 1;
    let { page, values } = this.state;
    let listCompanies = companies.map((c) => (
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={c.id_company}
        value={c.id_company}
        primaryText={c.name}
      >
        {c.name}
      </MenuItem>
    ));

    const toggleModal = (request) => {
      const user = this.store.getUserLoged();

      if (
        user.role != 'client' &&
        user.perfil &&
        user.perfil.user_create == false
      ) {
        this.store.toggleAlert(
          true,
          language.YOU_DO_NOT_HAVE_ACCESS_TO_CREATE_USERS,
          'error'
        );
      } else {
        this.setState({
          valuesedit: {
            email: request.email ? request.email : request.emailrequest,
          },
          reset: false,
          openDialogUser: true,
        });
        editvalor = [];
      }
    };

    let perfilList = [];
    perfilList.push({
      value: 0,
      label: language.NONE,
    });
    this.state.perfil.map((obj) =>
      perfilList.push({
        value: obj.perfil_id,
        label: obj.name,
      })
    );

    let typeOptions = [
      { value: 1, label: language.CLIENT },
      { value: 2, label: language.INTERNAL },
      { value: 3, label: language.OUTSOURCED },
    ];
    let listCompaniesUser = companies.map((c) => ({
      value: c.id_company,
      label: c.name,
    }));

    let role = this.store.getUserLoged().role;
    let csvData = [];

    let company =
      editvalor.id_company != undefined
        ? editvalor.id_company
        : id_company_selected;
    let type = values ? values.type : this.state.type;
    try {
      companiesOptions = this.state.companies.map((obj) => ({
        value: obj.id_company,
        label: obj.name,
        type: obj.type,
      }));
    } catch (e) {}

    if (company) {
      let companies = this.state.companies.filter(
        (c) => c.id_company == company
      );

      if (companies.length) {
        this.action
          .execute(
            'get',
            this.APIEndpoints.DEPARTMENT + '/' + companies[0].id_company,
            ''
          )
          .then((v) => {
            let listDepartments = v.data.map((c) => ({
              value: parseInt(c.id_department),
              label: c.name,
            }));
            this.setState({ departmentOptions: listDepartments });
          });
      }
    }

    let fields = [
      {
        col: 3,
        name: 'role',
        label: language.TYPE,
        startIcon: 'fas fa-users-cog',
        type: 'select', //select
        options: [
          { value: 'tech', label: language.TECH },
          { value: 'solicitant', label: language.SOLICITANT },
          { value: 'client', label: language.ADMIN },
        ],
        required: true,
      },
      {
        col: 3,
        name: 'name',
        label: language.NAME,
        startIcon: 'fas fa-signature',
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'hour_value',
        label: language.HOUR_VALUE,
        type: 'number',
      },
      {
        col: 3,
        name: 'email',
        label: language.EMAIL,
        startIcon: 'fas fa-envelope',
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'userad',
        label: language.AD_USER,
        type: 'text',
        required: false,
      },
      {
        col: 3,
        name: 'phone',
        label: language.PHONE,
        type: 'text',
        format: '(##) #####-####',
        placeholder: '(00)00000-0000',
        startIcon: 'fas fa-phone',
        required: true,
      },
      {
        col: 3,
        name: 'ramal',
        label: language.TELEPHONE_EXTENSION,
        type: 'text',
        required: false,
      },
      {
        col: 3,
        name: 'id_company',
        label: language.COMPANY,
        type: 'select',
        required: true,
        options: companiesOptions,
      },
    ];

    if (role === 'out') {
      fields.push();
    } else if (role === 'solicitant' || role === 'sponsor') {
      if (role === 'sponsor' && this.props.values && this.props.values.sponsor)
        this.props.values.role = 'sponsor';
      fields.push({
        col: 3,
        name: 'registration',
        label: language.REGISTRATION,
        type: 'text',
        required: false,
      });

      if (!this.state.removeDepart) {
        fields.push({
          col: 3,
          name: 'fk_id_department',
          label: language.DEPARTMENT,
          type: 'select',
          required: true,
          options: this.state.departmentOptions,
        });
      }
    } else if (role === 'tech') {
      fields.push({
        col: 3,
        name: 'type',
        label: language.TYPE,
        type: 'select',
        required: true,
        options: typeOptions,
        disabled: !!(values && values.type),
      });

      if (type === 1 || type === 3) {
      }
      fields.push({
        col: 3,
        name: 'fk_id_department',
        label: language.DEPARTMENT,
        type: 'select',
        required: true,
        options: this.state.departmentOptions,
      });
    } else if (role === 'client') {
      let companies = this.props.companies.filter((c) => c.id_company == 1);
      if (companies.length) {
      }

      fields.push({
        col: 3,
        name: 'fk_id_department',
        label: language.DEPARTMENT,
        type: 'select',
        required: true,
        options: this.state.departmentOptions,
      });
    }
    fields.push({
      col: 3,
      name: 'fk_id_perfil',
      label: language.PROFILE,
      type: 'select',
      required: false,
      options: perfilList,
    });

    let col = [
      {
        label: '',
        key: 'check',
        style: { width: 1, paddingRight: 0, paddingLeft: 0 },
        thConfig: true,
        search: <i></i>,
        //   <Checkbox className='-ml-1' onChange={(evt) => this.checkBoxSelectAll(evt.target.checked)} />
      },
      { label: language.DATE, key: 'dt_cad', style: { width: 100 } },
      {
        label: language.HOUR,
        key: 'dt_hr',
        style: { width: 1, paddingLeft: 0 },
        thConfig: true,
      },
      {
        label: language.ID,
        key: 'id',
        style: { paddingRight: 0, width: 100, textAlign: 'left' },
        thConfig: true,
      },
      {
        label: language.ACTION,
        key: 'action',
        style: { width: 50 },
        thConfig: true,
      },

      {
        label: language.COMPANY,
        key: 'company',
        style: { width: 200, paddingRight: 0 },
        thConfig: true,
      },
      { label: language.USER, key: 'user', style: { width: 100 } },
      {
        label: language.SUBJECT,
        key: 'subject',
        style: { width: 400, paddingRight: 0, paddingLeft: 0 },
        thConfig: true,
      },
      {
        label: language.DEPARTMENT,
        key: 'department',
        style: { width: 250 },
        thConfig: true,
      },
      {
        label: language.GROUP_TECH,
        key: 'grouptech',
        style: { width: 350 },
        thConfig: true,
      },
      {
        label: language.CATEGORY,
        key: 'category',
        style: { width: 250 },
        thConfig: true,
      },
      {
        label: language.SERVICE_CATALOG,
        key: 'service',
        style: { width: 250, paddingRight: 0, paddingLeft: 0 },
        thConfig: true,
      },
      {
        label: language.CATALOG_TASK,
        key: 'catalog_task',
        style: { width: 250 },
        thConfig: true,
      },
      {
        label: language.PRIORITY,
        key: 'priority',
        style: { width: 250 },
        thConfig: true,
      },
      {
        label: language.SLA,
        key: 'sla',
        style: { width: 70, paddingRight: 0 },
        thConfig: true,
      },
      {
        label: language.SLA_TIME,
        key: 'time',
        style: { width: 200 },
        thConfig: true,
      },
      {
        label: language.APPROVER,
        key: 'aprovador',
        style: { width: 200 },
        thConfig: true,
      },
    ];

    if (this.state.search != '') {
      requests = requests.filter((item) => {
        let valor = 'R' + item.id_request.toString().toUpperCase();
        return (
          item.subject.toUpperCase().indexOf(this.state.search.toUpperCase()) !=
            -1 ||
          item.description
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) != -1 ||
          valor.indexOf(this.state.search.toUpperCase()) != -1 ||
          item.name.toUpperCase().indexOf(this.state.search.toUpperCase()) != -1
        );
      });
    }

    if (!!this.state.companyId) {
      requests = requests.filter(
        (c) => c.fk_id_company === this.state.companyId
      );
    }

    if (this.props.approve == true) {
      requests = requests.filter((c) => c.approval === true);
    }

    //let departmentNames = departments.map(dpt => dpt.name);

    let comp = companies.map((dpt) => dpt.name);

    //FILTRAR POR TEMPO

    if (this.state.params.length > 0) {
      requests = requests.filter(
        (request) =>
          (moment(request.dt_cad).add(
            this.state.params[0].vision_request,
            'days'
          ) < moment() &&
            (request.status == 'Cancelled' || request.status == 'Reject')) ||
          request.status == 'Open' ||
          request.status == 'Approve' ||
          request.status == 'Approved' ||
          request.status == null
      );
    } else {
      //   requests = requests.filter((request) => moment(request.dt_cad) > moment())
    }
    let data = this.props.requests.map((obj) => {
      let opens = true;
      let itens = [];

      let sla = TicketUtils.calcSla(
        { ...obj, dt_cad: obj.status == 'Approved' ? obj.dt_up : obj.dt_cad },
        'request',
        language,
        [],
        1,
        true,
        ['Reject', 'Cancelled'].includes(obj.status) ? 1 : 0,
        null,
        obj.type
      );

      let valor = false;
      let aprovadores = [];

      if (view?.length > 0) {
        if (obj.chief != null) {
          try {
            aprovadores = this.props.view.filter(
              (b) => b.email == obj.username || b.codusuariorede == obj.userad
            )[0].gestor;
          } catch (e) {}
          if (aprovadores == this.props.userad) {
            valor = true;
          }
        }
      } else {
        if (obj.chief != null) {
          obj.chief.map((y) => {
            users
              .filter((h) => h.id_user == y)
              .map((u) => {
                aprovadores.push(<div>{u.name}</div>);
              });
          });
          if (obj.chief.includes(this.props.iduser)) {
            valor = true;
          }
        }
      }

      let grouptech = null;

      if (mailcontracts.length > 0) {
        if (obj.nameusers == null) {
          grouptech = (
            <Tooltip
              interactive
              html={
                <div className="view-catalogs-tooltip">
                  {mailcontracts
                    .filter(
                      (item) =>
                        item.fk_id_company == obj.fk_id_company &&
                        item.fk_id_mail == obj.fk_id_mail
                    )
                    .map((string) => (
                      <li>{string.name}</li>
                    ))}
                </div>
              }
              arrow={true}
              position="left"
              theme="light"
            >
              {mailcontracts.filter(
                (item) =>
                  item.fk_id_company == obj.fk_id_company &&
                  item.fk_id_mail == obj.fk_id_mail
              ).length > 0 ? (
                <i
                  class="fas fa-eye"
                  style={{ fontSize: 15, color: 'var(--warning)' }}
                >
                  {
                    mailcontracts.filter(
                      (item) =>
                        item.fk_id_company == obj.fk_id_company &&
                        item.fk_id_mail == obj.fk_id_mail
                    )[0].email
                  }
                </i>
              ) : null}
            </Tooltip>
          );
        }
      }
      return {
        check:
          obj.status != 'Approve' ? (
            <Checkbox
              checked={
                !!this.state.ticketsArray.find((e) => obj.id_request == e)
              }
              onChange={() =>
                this.checkBoxSelect(
                  obj.id_request,
                  obj.fk_id_company_fk,
                  language
                )
              }
            />
          ) : approve == true && this.store.getUserLoged().role == 'client' ? (
            <Checkbox
              checked={
                !!this.state.ticketsApproveToEmail.find(
                  (e) => obj.id_request == e
                )
              }
              onChange={() => this.checkApproveToEmail(obj.id_request)}
            />
          ) : null,
        dt_cad: moment(obj.dt_cad).format('L'),
        dt_hr: moment(obj.dt_cad).format('HH:mm'),
        id: 'R' + obj.id_request,
        action:
          //    obj.id_user>0?
          obj.id_user > 0 ? (
            <div
              className="col-action"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {obj.approval && (valor == true || role == 'client') ? (
                <Button
                  icon="fas fa-award"
                  variant="circle"
                  style={{ border: 'none' }}
                  className="mr-2"
                  outlined
                  size={0.7}
                  label={language.APPROVAL}
                  onClick={() => handlePage('approve', obj)}
                  //  onClick={() => redirect2(pathname, obj)}
                />
              ) : null}

              {obj.status == 'Reject' ? (
                <div className="mr-2">
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.CANCELLED}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <div
                      style={{ width: 25, height: 25 }}
                      className="cancelledIcon pointer"
                    />
                  </Tooltip>
                </div>
              ) : null}

              <Button
                icon="fas fa-eye"
                variant="circle"
                style={{ border: 'none' }}
                className="mr-2"
                outlined
                size={0.7}
                label={language.VISUALIZE}
                onClick={() => handlePage('view', obj)}
              />

              {this.props.solicit == false ? (
                obj.status == 'Approve' || obj.status == 'Reject' ? null : (
                  <Button
                    icon="fas fa-tag"
                    variant="circle"
                    style={{ border: 'none' }}
                    outlined
                    size={0.7}
                    className="mr-2"
                    label={language.CATEGORIZE}
                    onClick={() =>
                      catHandle({
                        subject: obj.subject,
                        description: obj.description,
                        fk_id_user: obj.id_user,
                        fk_id_request: obj.id_request,
                        fk_id_company:
                          obj.fk_id_company_fk > 0
                            ? obj.fk_id_company_fk
                            : obj.fk_id_company,
                        fk_id_category: obj.fk_id_category,
                        fk_id_catalog_service: obj.fk_id_catalog_service,
                        fk_id_catalog_task: obj.fk_id_catalog_task,
                        fk_id_sla: obj.fk_id_sla,
                        html_values: obj.html_values,
                        template: obj.template !== null ? obj.template : null,
                        ticketArray: [],
                        req: [],
                        fk_id_device: obj.fk_id_device,
                        fk_id_contract: obj.fk_id_contract,
                        files: obj.files,
                        name_company: obj.name_company_fk || obj.name_company,
                        solicitant: obj.name,
                        department: obj.department,
                        type_ticket_cat: obj.type_ticket_cat,
                        fk_id_channel: obj.fk_id_channel,
                        OpenCategorize: true,
                      })
                    }
                  />
                )
              ) : null}
              {obj.status != 'Reject' ? (
                <Button
                  icon="fas fa-trash"
                  variant="circle"
                  style={{ border: 'none' }}
                  outlined
                  size={0.7}
                  color="danger"
                  label={
                    this.props.solicit == false
                      ? language.REJECT
                      : language.CANCEL
                  }
                  onClick={() =>
                    handlePage('reject', {
                      previousPage: 'list',
                      id_request: obj.id_request,
                    })
                  }
                />
              ) : null}
            </div>
          ) : (
            <div>
              {' '}
              <Button
                icon=" fas fa-plus-circle"
                variant="circle"
                style={{ border: 'none' }}
                className="mr-2"
                outlined
                size={0.7}
                label={language.USER_REGISTRATION}
                //  onClick={() => redirect2(`/dash/configuration/users?${obj.emailrequest}`, )}
                onClick={() => toggleModal(obj)}
              />
              <Button
                icon="fas fa-eye"
                variant="circle"
                style={{ border: 'none' }}
                className="mr-2"
                outlined
                size={0.7}
                label={language.VISUALIZE}
                onClick={() => handlePage('view', obj)}
              />
              <Button
                icon="fas fa-trash"
                variant="circle"
                style={{ border: 'none' }}
                outlined
                size={0.7}
                color="danger"
                label={
                  this.props.solicit == false
                    ? language.REJECT
                    : language.CANCEL
                }
                onClick={() =>
                  handlePage('reject', {
                    previousPage: 'list',
                    id_request: obj.id_request,
                  })
                }
              />
            </div>
          ),

        company: obj.name_company_fk || obj.name_company,
        user: (
          <div>
            {' '}
            {obj.vip ? (
              <Icon color="primary" style={{ fontSize: 16, marginBottom: -4 }}>
                star
              </Icon>
            ) : null}
            <label>{obj.name}</label>
          </div>
        ),
        subject: obj.subject,
        department: obj.department,
        grouptech: grouptech || obj.nameusers,
        category: obj.namecategory_,
        service: obj.catalogservicename_,
        catalog_task: obj.catalogtaskname_,
        priority: translateFromPtBR(obj.priority, language),
        sla: obj.type == 'M' ? obj.sla + ' m' : obj.sla + ' h',
        time: (
          <div className="d-flex align-items-center">
            <div
              style={{
                width: 15,
                height: 15,
                background: sla.color,
                borderRadius: '50%',
                float: 'left',
                marginRight: 5,
              }}
            />
            <span style={{ color: sla.color }}>
              {obj.status == 'Approve' ? '00' : sla.time}
            </span>
          </div>
        ),
        aprovador: obj.chiefname
          ? generateApproversTooltip(obj.chiefname)
          : obj.approval
          ? generateApproversTooltip(aprovadores)
          : '',
      };
    });

    let btn = null;
    if (approve == true && this.state.ticketsApproveToEmail.length > 0) {
      btn = (
        <Button
          icon="fas fa-envelope"
          variant="circle"
          color="warning"
          label={`${language.SEND} ${language.EMAIL}`}
          onClick={this.sendEmailToApprovers}
        />
      );
    }
    if (this.state.ticketsArray.length > 1) {
      let obj = requests
        .filter((c) => c.id_request === this.state.ticketsArray[0])
        .map((c) => {
          return c;
        });
      obj = obj[0];

      this.state.ticketsArray[0];
      let ticketsArray = this.state.ticketsArray;

      btn =
        this.props.solicit != 'solicitant' ? (
          <div className="d-flex align-items-center">
            <Button
              icon="fas fa-tags"
              variant="circle"
              style={{ border: 'none' }}
              className="mr-2"
              outlined
              color="warning"
              label={language.CATEGORIZE_SELECTED}
              onClick={() => (
                catHandle(
                  {
                    subject: language.IT_IS_NOT_NECESSARY_TO_INFORM,
                    description: language.IT_IS_NOT_NECESSARY_TO_INFORM,
                    fk_id_user: obj.id_user,
                    fk_id_request: obj.id_request,
                    fk_id_company:
                      obj.fk_id_company_fk > 0
                        ? obj.fk_id_company_fk
                        : obj.fk_id_company,
                    fk_id_category: obj.fk_id_category,
                    fk_id_catalog_service: obj.fk_id_catalog_service,
                    fk_id_catalog_task: obj.fk_id_catalog_task,
                    fk_id_sla: obj.fk_id_sla,
                    fk_id_priority: obj.fk_id_priority,
                    fk_id_urgency: obj.fk_id_urgency,
                    id_impact: obj.id_impact,
                    fk_id_group_tech: obj.fk_id_group_tech,
                    ticketArray: ticketsArray,

                    fk_id_device: obj.fk_id_device,
                    fk_id_contract: obj.fk_id_contract,
                    files: obj.files,
                    req: requests,
                    name_company: obj.name_company_fk || obj.name_company,
                    solicitant: obj.name,
                    department: obj.department,
                    type_ticket_cat: obj.type_ticket_cat,
                    fk_id_channel: obj.fk_id_channel,
                  },
                  ticketsArray
                ),
                (this.state.ticketsArray = [])
              )}
            />
            <Button
              icon="fas fa-trash"
              variant="circle"
              style={{ border: 'none' }}
              outlined
              color="danger"
              label={language.REJECT_SELECTED}
              onClick={() =>
                handlePage('reject', {
                  previousPage: 'list',
                  id_request: ticketsArray,
                })
              }
            />
          </div>
        ) : null;
    }

    return (
      <PageLayout
        icon="requesterIcon titleIconSize"
        title={
          window.location.href.indexOf('approve') > -1
            ? language.APPROVE_REQUESTS
            : this.props.solicit == true
            ? language.SOLICITACAO
            : language.CATEGORIZATION
        }
        subTitle={language.DASHBOARD_PAGE.DM + '/'}
        rightElements={[]}
      >
        <div style={{ marginTop: '-1rem' }}>
          <CommonTable
            data={data}
            col={col}
            searchColumn
            beginWithNoFilter={true}
            orderColumn
            onClearAllFilter={this.cleanAllFilters}
            paginationTop={false}
            searchColumnEvent={(v) => {
              this.props.callPageSelectFilter(0, v, 75);
            }}
            loadSearch={true}
            isAutomaticPagination={false}
            onSortItem={(item, sortByStatus) =>
              this.props.callSortItem(item, sortByStatus)
            }
            currentPage={this.state.currentPage}
            callPageSelectFilter={(page, values, rowsPerPage, type) =>
              this.props.callPageSelectFilter(page, values, rowsPerPage, type)
            }
            noFilter
            beforeBar={[
              <Button
                variant="circle"
                icon="fas fa-sync"
                color="warning"
                className="mr-3"
                onClick={() => window.location.reload()}
                label={language.REFRESH_PAGE}
              />,
              <Button
                variant="circle"
                label={language.NEW_REQUEST}
                icon="fas fa-plus"
                color="warning"
                className="mr-3"
                onClick={() => this.handleNewRequest()}
              />,
              approve && (
                <Button
                  variant="circle"
                  icon="fas fa-file-csv"
                  label={language.EXPORT + ' ' + language.REQUESTS}
                  color="warning"
                  className="mr-3"
                  style={{
                    fontSize: 15,
                  }}
                  onClick={downloadRequests}
                />
              ),
            ]}
            countTotal={this.props.totalrequest}
            beforeBar2={btn}
          />
        </div>
        {request && this.props.page != 'reject' ? (
          <RequestApproval
            language={language}
            id={request.id}
            fromDash={request.fromDash}
            fromDashApprove={request.fromDashApprove}
            request={request}
            redirect2={this.redirect}
            {...this.props}
          />
        ) : null}

        <Dialog
          style={{ height: '250px' }}
          title={language.GENERAL_USER_CREATION}
          open={this.state.openDialogUser}
          onClose={() => this.setState({ openDialogUser: false })}
          maxWidth="lg"
          monitor_scroll
        >
          <CommonForm
            fields={fields}
            values={this.state.valuesedit}
            onChangeField={this.onChangeField}
            onSubmit={(values) => this.onFormUserSubmit(values, language)}
            button={{
              label: language.SAVE,
            }}
          />
        </Dialog>
      </PageLayout>
    );
  }
}
