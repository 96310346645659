import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import PageLayout from '@common/PageLayout';
import Progress from '@common/Progress';
import Tag from '@common/Tag';
import { Checkbox } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-tippy';
import TicketUtils from '../../util/TicketUtils';
import ControllerTicket from './ControllerTicket';
import DialogForm from './DialogForm';
import IntegrationDetail from './IntegrationDetail';
import './List.css';
import Notify from './Notification';
import Sla from './Sla';

// --------------------------------icons----------------------------------
import Constants from '@data/Constants';
import Icon from '@material-ui/core/Icon';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { translateFromPtBR } from '../../common/languages/Dictionary';
import DownloadFiles from '../../util/DownloadFiles';
import { EditMultipleTickets } from './EditMultipleTickets';
import SearchForm from './SearchForm';

export default function ({
  store,
  action,
  APIEndpoints,
  handleDetail,
  openTicket,
  abas,
  detail,
  closeDetail,
  language,
  user,
  redirect,
  TICKETS_THEME,
  requests,
  prioritiesIndex,
  onNotesTicket,
  answer,
  integration,
  onNotesTicketTab,
  params,
  filterStatus,
  setFilterStatus,
  autoloadTickets,
  setAutoloadTickets,
  onChangeStatus_Tab,
  onCommentTicket
}) {
  const statusTicketFilter = [
    { label: language.ALL, value: 'Todos' },
    { label: language.TICKET_STATUS.OPENS, value: 'Open' },
    { label: language.TICKET_STATUS.IN_PROGRESS, value: 'In Progress' },
    { label: language.TICKET_STATUS.SOLVED, value: 'Resolved' },
    { label: language.TICKET_STATUS.CLOSED, value: 'Closed' },
    { label: language.TICKET_STATUS.REOPEN, value: 'Reopened' },
    { label: language.TICKET_STATUS.WAITING, value: 'Aguardando' },
    { label: language.TICKET_STATUS.TASKS, value: 'Job' },
  ];

  const [generalParams, setGeneralParams] = useState([]);
  const [ticketsArray, setticketsArray] = useState([]);
  const [ticketsDescArray, setTicketsDescArray] = useState([]);
  const [ticketsTypeTicketArray, setTicketsTypeTicketArray] = useState([]);  
  const [modalClose, setModalClose] = useState(false);
  const [msn, setmsn] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [complexities, setComplexities] = useState([]);
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
  const [stopSlaParams, setstopSlaParams] = useState(false);
  const [anchorEl, setAnchoEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [ticket, setTicket] = useState(0);
  const [statusAwaiting, setStatusAwaiting] = useState([]);
  const [timeRefresh, setTimeRefresh] = useState(0);
  const [desc, setDesc] = useState(null);

  const [filterGroupTech, setFilterGroupTech] = useState({});
  const [typeTicketFilter, setTypeTicketFilter] = useState([]);
  const [filterColumns, setFilterColumns] = useState({});
  const [filterFind, setFilterFind] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(75);
  const [tickets, setTickets] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);
  const [sortBy, setSortBy] = useState('grid_date');
  const [sortByDesc, setSortByDesc] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [autoloadChange, setAutoloadChange] = useState(0);

  useEffect(() => {
    action.execute('get', APIEndpoints.GENERAL_PARAMETERS, '').then((res) => {
      setGeneralParams(res.data);
    });

    action
      .execute('get', APIEndpoints.TABLE_STRUCTURED + '/60', 'priorities')
      .then((v) => {
        setPriorities(v.data);
      });

    action
      .execute('get', APIEndpoints.TABLE_STRUCTURED + '/130', 'complexities')
      .then((v) => {
        setComplexities(v.data);
      });

    action
      .execute('get', APIEndpoints.STATUS_AWAITING, 'status_awaiting')
      .then((e) => {
        setStatusAwaiting(e.data);
      })
      .catch((error) => {});

    action
      .execute('get', Constants.APIRoot + '/api/generalparameters')
      .then((e) => {
        setTimeRefresh(e.data.time_refresh);
      })
      .catch((error) => {});

    let interval = null;
    action
      .execute('get', Constants.APIRoot + '/api/generalparameters')
      .then((e) => {
        const time_refresh = e.data.time_refresh;
        if (typeof time_refresh === 'number' && time_refresh > 0) {
          interval = setInterval(
            () => {
              params = JSON.parse(localStorage.getItem('paramsTicketManagement'));
              if( params?.autoloadTickets || autoloadTickets ) {
                getFiltersFromStorage();
              }
            },
            time_refresh * 60 * 1000,
          );
        }
      })
      .catch((error) => {});

    getFiltersFromStorage();

    return () => {
      clearInterval(interval);
    };
  }, []);

  let open = !!anchorEl;
  let inte = store.getUserLoged().integration;

  async function callPageSelectFilter(page, values, totalPerPage) {
    setCurrentPage(page);
    setTotalPerPage(totalPerPage);
    if (JSON.stringify(values) !== JSON.stringify(filterColumns)) {
      setCurrentPage(0);
    }
    setFilterColumns(values);
  }

  function searchColumnEvent({ search }) {
    setCurrentPage(0);
    setFilterFind(search);
  }

  function cleanFilters() {
    setCurrentPage(-1);
    setFilterStatus([statusTicketFilter[0]]);
    setTypeTicketFilter([]);
    setFilterColumns({});
    setFilterFind('');
  }
  function searchId(item) {
    let values = { grid_id: item.search };
    setCurrentPage(0);
    this.callPageSelectFilter(0, values, 75);
  }

  const sendvalues = (data) => {
    store.spinner = true;
    data.ticketsList = ticketsArray
    let values = { filter: data };
    user;
    values.id_user = store.getUserLoged().id;
    values.role = store.getUserLoged().role;

    try {
      values.filter.statusFilter = JSON.parse(
        localStorage.getItem('paramsTicketManagement')
      ).filterStatus.map((g) => g.value);
    } catch (r) {
      values.filter.statusFilter = [];
    }
    values.filter.filterColumns = JSON.parse(
      localStorage.getItem('paramsTicketManagement')
    ).filterColumns;
    
    const storedParams = JSON.parse(localStorage.getItem('paramsTicketManagement'));
    if (Array.isArray(storedParams?.typeTicketFilter)) {
      values.filter.typeTickets = storedParams.typeTicketFilter;
    } else {
      values.filter.typeTickets = [storedParams.typeTicketFilter]; // Converte para array se necessário
    }

    values.filter.GroupTech = JSON.parse(
      localStorage.getItem('paramsTicketManagement')
    ).filterGroupTech;

    values.norequest = true;
    values.type = "XLS";
    values.historic = true;
    values.dh = true;
    values.getfile = true;
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.REPORTEXPORT}/ticket/dash`,
        'tickets',
        values,
        null,
        null,
        null
      )
      .then((g) => {
        store.spinner = false;
        setTimeout(() => {
          DownloadFiles(
            Constants.APIEndpoints.REPORTEXPORT +
            '/' +
            store.getUserLoged().id +
            '/' +
            g.data.file,
            g.data.file
          );
        }, 3000);
      });
  };

  const sendValuesPdf = (data) => {
    store.spinner = true;
    data.ticketsList = ticketsArray
    let values = { filter: data };
    user;
    values.id_user = store.getUserLoged().id;
    values.role = store.getUserLoged().role;

    try {
      values.filter.statusFilter = JSON.parse(
        localStorage.getItem('paramsTicketManagement')
      ).filterStatus.map((g) => g.value);
    } catch (r) {
      values.filter.statusFilter = [];
    }
    values.filter.filterColumns = JSON.parse(
      localStorage.getItem('paramsTicketManagement')
    ).filterColumns;
    values.filter.typeTickets = JSON.parse(
      localStorage.getItem('paramsTicketManagement')
    ).typeTicketFilter;
    values.filter.GroupTech = JSON.parse(
      localStorage.getItem('paramsTicketManagement')
    ).filterGroupTech;

    values.norequest = true;
    values.type = "PDF";
    values.historic = true;
    values.dh = true;
    values.getfile = true;
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.REPORTEXPORT}/ticket/dash`,
        'tickets',
        values,
        null,
        null,
        null
      )
      .then((g) => {
        store.spinner = false;
        setTimeout(() => {
          DownloadFiles(
            Constants.APIEndpoints.REPORTEXPORT +
            '/' +
            store.getUserLoged().id +
            '/' +
            g.data.file,
            g.data.file
          );
        }, 3000);
      });
  };

  const onFormSubmitItem = (values) => {
    let filesSend = [];
    let cont = 0;

    let total = values.files.length;
    Array.from(values.files).forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });
      promise.then((result) => {
        if (file.size >= 40000000) {
          alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
          this.setState({ sendFile: false });
          filesSend = [];
          //this.state.files = [];
          cont = 0;
        }
        filesSend.push({
          file: result,
          type: file.type,
          namefile: file.name,
        });
        cont = cont + 1;
        if (total === cont) {
          values.file = filesSend;
          let tokenApi = store.getToken();

          action
            .execute('post', `${APIEndpoints.TICKET}`, '', values)
            .then((data) => {
              if( data.data ) {
                data = data.data;
              }
              data.real_id = data.config[0].real_id;
              openTicket(data, true);
              setTimeout(() => {
                handleDetail(data.id_tickets);
              }, 2000);
            });
        }
      });
    });
  };

  const onFormSubmitTab = (values) => {
    if (values.files.length > 0) {
      onFormSubmitItem(values);
    } else {
      action
        .execute('post', APIEndpoints.TICKET, 'ticket', values)
        .then((h) => {
          setOpenDialog(false);
          h.data.real_id = h.data.config[0].real_id;
          openTicket(h.data, true);
          setTimeout(() => {
            handleDetail(h.data.id_tickets);
          }, 2000);
        });
    }
  };

  function onSubmitAdvancedSearch(v) {
    if (v.type && v.search) {
      action
        .execute(
          'get',
          `${APIEndpoints.TICKET}/advancedSearch?type=${v.type}&search=${v.search}`,
          'tickets',
          null,
          null,
          { btn_loading: 'search_advanced_search' }
        )
        .then((g) => {
          setTickets(g.data);
          setAdvancedSearchModalOpen(false);
        });
    }
  }

  useEffect(() => {
    if (Array.isArray(prioritiesIndex))
      setPriorities(prioritiesIndex);
  }, [prioritiesIndex]);

  const getFiltersFromStorage = () => {
    let params = localStorage.getItem('paramsTicketManagement');
    if (params) {
      params = JSON.parse(params);
      if (params?.filterStatus) {
        setFilterStatus(params?.filterStatus);
      }
      setFilterColumns(params?.filterColumns || {});
      setFilterFind(params?.filterFind || '');
      setCurrentPage(params?.currentPage || 0);
      setTotalPerPage(params?.totalPerPage || 75);
      setFilterGroupTech(params?.filterGroupTech || {});

      if (Array.isArray(params?.typeTicketFilter)) {
        setTypeTicketFilter(params?.typeTicketFilter);
      } else {
        setTypeTicketFilter([params?.typeTicketFilter]);
      }

      setAutoloadTickets(params?.autoloadTickets);
      if( params?.autoloadTickets || autoloadTickets ) {
        setAutoloadChange(prev => prev + 1);
      }
    } else {
      if( params?.autoloadTickets || autoloadTickets ) {
        setAutoloadChange(prev => prev + 1);
      } else {
        refreshGrid();
      }
    }
  };

  let columns = [
    {
      key: 'check_',
      label: '#',
      thConfig: true,
      search: false,
      noOrder: true,
    },
    {
      key: 'check',
      label: '#',
      thConfig: true,
      search: false,
      noOrder: true,
    },
    { key: 'grid_status', label: language._PROGRESS },
    { key: 'grid_date', label: language.OPENING },
    { key: 'grid_hour', label: language.INITIAL_HOUR },
    { key: 'grid_reopened_count', label: language.REOPEN_COUNT },
    { key: 'grid_date_f', label: language.RESOLVED },
    { key: 'grid_hour_f', label: language.END_HOUR },
    { key: 'grid_id', label: language.ID },
    {
      key: 'grid_id_integration',
      label: language.INTEGRATION,
    },
    {
      key: 'grid_company', label: language.COMPANY,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_company',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.COMPANY}/all?`,
      filterAll: {},
      textinit: language.COMPANY,
      textlabel: language.COMPANY,
      idinit: [],
      fieldquery: 'search',
      routeGetCount: `${APIEndpoints.COMPANY}/count`,
    },
    {
      key: 'grid_location', label: language.LOCATION,
    },
    {
      key: 'grid_user',
      label: language.REQUESTER,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.USER}/filter/all?`,
      filterAll: {},
      textinit: language.REQUESTER,
      textlabel: language.REQUESTER,
      idinit: [],
      fieldquery: 'name',
      routeGetCount: `${APIEndpoints.USER}/active/count/0`,
    },
    {
      key: 'grid_subject', label: language.SUBJECT
    },
    {
      key: 'grid_tech_group', label: language.TECH_GROUP,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_group_users',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.GROUP}/all?type=1&`,
      filterAll: { type_search: 1, fromCommonTable: true },
      textinit: language.TECH_GROUP,
      textlabel: language.TECH_GROUP,
      idinit: [],
      fieldquery: 'search',
      routeGetCount: `${APIEndpoints.GROUP}/count`,
    },
    {
      key: 'grid_tech_solicitant', label: language.REQUESTING_TECHNICIAN
    },
    {
      key: 'grid_service_technician', label: language.SERVICE_TECH,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.USER}/filter/notsolicitant?`,
      filterAll: { fromCommonTable: true },
      textinit: language.SERVICE_TECH,
      textlabel: language.SERVICE_TECH,
      idinit: [],
      fieldquery: 'search',
      showEmptyFilter: true,
      routeGetCount: `${APIEndpoints.USER}/active/count/0`,
    },
    { key: 'grid_channel', label: language.CHANNEL },
    { key: 'grid_priority', label: language.PRIORITY },
    {
      key: 'grid_complexity',
      label: language.COMPLEXITY,
      multiFilter: true,
      valuesColumn: complexities.map((complexity) => {
         return {
           value: complexity.id_table,
           label: translateFromPtBR(complexity.description, language)
         };
      }),
      fieldvaluedb: 'value',
      fieldlabeldb: 'label',
    },
    { key: 'grid_sla', label: language.SLA },
    { key: 'grid_sla_time', label: language.SLA_TIME, search: false },
    {
      key: 'grid_waiting',
      label: language.STATUS,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_status',
      fieldlabeldb: 'name',
      translateOptions: true,
      routeAll: `${APIEndpoints.STATUS_AWAITING}/all?`,
      filterAll: {},
      textinit: language.STATUS,
      textlabel: language.STATUS,
      idinit: [],
      filterAll: { waiting: false },
      fieldquery: 'search',
      routeGetCount: `${APIEndpoints.STATUS_AWAITING}/count`,

    },
    {
      key: 'grid_reason_status',
      label: language.REASON_STATUS,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_status',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.STATUS_AWAITING}/all?`,
      filterAll: {},
      textinit: language.STATUS,
      textlabel: language.STATUS,
      filterAll: { waiting: true },
      idinit: [],
      fieldquery: 'search',
      showEmptyFilter: true,
      routeGetCount: `${APIEndpoints.STATUS_AWAITING}/count`,
    },
    {
      key: 'department',
      label: language.DEPARTMENT,
      showEmptyFilter: true,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_department',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
      filterAll: {},
      textinit: language.DEPARTMENT,
      textlabel: language.DEPARTMENT,
      idinit: [],
      fieldquery: 'search',
      routeGetCount: `${APIEndpoints.DEPARTMENT}/count?`,
    },

    {
      key: 'grid_category', label: language.CATEGORY,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_category',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.CATEGORY}/list?`,
      filterAll: {},
      textinit: language.CATEGORY,
      textlabel: language.CATEGORY,
      idinit: 0,
      fieldquery: 'search',
      routeGetCount: `${APIEndpoints.CATEGORY}/list?`,
    },
    {
      key: 'grid_catalog_service',
      label: language.SUBCATEGORY,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_catalog_service',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.CATALOG}/category/all?`,
      filterAll: {},
      textinit: language.CATALOG,
      textlabel: language.CATALOG,
      idinit: 0,
      fieldquery: 'search',
      routeGetCount: `${APIEndpoints.CATALOG}/category/all?`,
    },
    {
      key: 'grid_catalog_task',
      label: language.THIRD_LEVEL_CATEGORY,
      multiOption: params[0] && params[0].use_multi_filter_ticket ? true : false,
      fieldvaluedb: 'id_catalog_task',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.TASK}/list?`,
      filterAll: {},
      textinit: language.THIRD_LEVEL,
      textlabel: language.THIRD_LEVEL,
      idinit: 0,
      fieldquery: 'search',
      routeGetCount: `${APIEndpoints.TASK}/list?`,
    },
    { key: 'grid_time_spent', label: language.TIME_SPENT },
    {
      key: 'grid_correlation_display',
      label: language.ID_DISPLAY,
    },
  ];

  const datas = useMemo(() => {
    return tickets && Array.isArray(tickets) &&
    tickets.map((t, index) => {

      let req = [];

      if (t.dt_cad_request != null) {
        req = [
          {
            dt_up: t.dt_end_request,
            dt_cad: t.dt_cad_request,
            id_request: t.fk_id_request,
          },
        ];
      }

      t.time_resolution = t.time_resolution_problem || t.time_resolution;

      let horas = undefined;
      let arrayPriority = [
        language.TICKET_PRIORITY.SMALL,
        language.TICKET_PRIORITY.MEDIUM,
        language.TICKET_PRIORITY.HIGH,
        language.TICKET_PRIORITY.CRITICAL,
      ];
      let arrayPriorityColor = [
        params[0]?.low_ticket_color,
        params[0]?.medium_ticket_color,
        params[0]?.high_ticket_color,
        params[0]?.critical_ticket_color
      ];
      let arrayComplexity = [
         language.TICKET_PRIORITY.SMALL,
         language.TICKET_PRIORITY.MEDIUM,
         language.TICKET_PRIORITY.HIGH,
         language.TICKET_PRIORITY.CRITICAL,
      ];

      let dados = {
        ConfigCell: {
          __order_priority: t.fk_id_priority,
        },
        check_:
          t.status !== 'Cancelled' &&
            t.status !== 'Resolved' &&
            t.status !== 'Closed' &&
            (generalParams?.fk_id_perfil_mass_change ? JSON.parse(generalParams?.fk_id_perfil_mass_change).length > 0 ? 
            generalParams?.fk_id_perfil_mass_change?.includes(store.getUserLoged()?.perfil?.perfil_id) : true : true) ? (
            <Checkbox
              checked={ticketsArray.includes(t.id_tickets)}
              onClick={(e) => {
                setticketsArray((oldArray) =>
                  ticketsArray.includes(t.id_tickets)
                    ? ticketsArray.filter((item) => item !== t.id_tickets)
                    : [...oldArray, t.id_tickets]
                );
                setTicketsDescArray((oldArray) =>
                  ticketsArray.includes(t.real_id)
                    ? ticketsArray.filter((item) => item !== t.real_id)
                    : [...oldArray, t.real_id]
                );
                setTicketsTypeTicketArray((oldArray) =>
                  ticketsArray.includes(t.real_id)
                    ? ticketsArray.filter((item) => item !== t.type_tickets)
                    : [...oldArray, t.type_tickets]
                );
              }}
            />
          ) : null,

        check: (
          <div className="d-flex aling-items-center">
            {t.type_tickets == 4 ? (
              <Tooltip
                html={
                  <span style={{ color: '#fff', fontSize: 15 }}>
                    {language.REQUEST}
                  </span>
                }
                position="right"
                theme="dark"
              >
                <i
                  onClick={() => openTicket(t, true)}
                  className="fas fa-bullhorn icon my-1"
                  style={{
                    color: 'var(--primary)',
                    cursor: 'pointer',
                    fontSize: 13,
                  }}
                />
              </Tooltip>
            ) : t.type_tickets == 2 ? (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.TICKET_TYPES.PROBLEM}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <WarningIcon
                  onClick={() => openTicket(t, true)}
                  style={{
                    color: ' var(--danger)',
                    cursor: 'pointer',
                    fontSize: 18,
                  }}
                />
              </Tooltip>
            ) : t.type_tickets == 1 ? (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.TICKET_TYPES.INCIDENT}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <NotificationsIcon
                  onClick={() => openTicket(t, true)}
                  style={{
                    color: 'var(--warning)',
                    cursor: 'pointer',
                    fontSize: 17,
                  }}
                />
              </Tooltip>
            ) : t.type_tickets == 5 ? (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.TICKET_TYPES.EVENT}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <AssignmentLateIcon
                  onClick={() => openTicket(t, true)}
                  style={{
                    color: 'var(--warning)',
                    cursor: 'pointer',
                    fontSize: 17,
                  }}
                />
              </Tooltip>
            ) : t.type_tickets == 6 ? (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{t.documented_crisis ? language.TICKET_TYPES.DOCUMENTED_CRISIS : language.TICKET_TYPES.CRISIS}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <ArrowDownwardIcon
                  onClick={() => openTicket(t, true)}
                  style={{
                    color: `${t.documented_crisis ? 'var(--primary)': 'var(--warning)'}`,
                    cursor: 'pointer',
                    fontSize: 17,
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.TICKET_TYPES.CRITICAL_INCIDENT}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <ErrorIcon
                  onClick={() => openTicket(t, true)}
                  style={{
                    color: 'var(--danger)',
                    cursor: 'pointer',
                    fontSize: 17,
                  }}
                />
              </Tooltip>
            )}
            <Notify msn={msn} t={t} socket={store.socket}></Notify>
          </div>
        ),

        grid_status: (
          <div className="d-flex align-items-center" style={{ width: 75 }}>
            <Progress
              current={t.grid_status}
              hiddenText
              background="var(--primary)"
              style={{ height: 7, width: '100%' }}
            />
            <span className="ml-2 status" style={{ fontSize: 10 }}>
              {t.grid_status}%
            </span>
          </div>
        ),

        grid_date: t.dt_cad_request
          ? moment(t.dt_cad_request).format('DD/MM/YYYY')
          : moment(t.grid_date).format('DD/MM/YYYY'),
        grid_hour: t.dt_cad_request
          ? moment(t.dt_cad_request).format('HH:mm')
          : t.grid_hour,
        grid_reopened_count: t.grid_reopened_count,
        grid_date_f:
          t.progress == 100 && moment(t.grid_date_f).format('DD/MM/YYYY'),
        grid_hour_f: t.grid_hour_f,
        grid_id: t.grid_id,
        grid_id_integration: t.id_integration,
        grid_company: t.grid_company,
        grid_location: t.grid_location,
        grid_user: (
          <div>
            {' '}
            {t.vipuser ? (
              <Icon
                color="primary"
                style={{ fontSize: 16, marginBottom: -4 }}
              >
                star
              </Icon>
            ) : null}
            <label>{t.grid_user}</label>
          </div>
        ),

        grid_subject: t.grid_subject,

        grid_tech_group: t.grid_tech_group,
        grid_tech_solicitant: t.techsol,
        grid_service_technician: t.nametech,
        grid_channel: translateFromPtBR(t.channel, language),
        grid_priority: (
          <Tag
            label={
              arrayPriority[
              t.fk_id_priority ==
                (!!prioritiesIndex.length ? prioritiesIndex[0].id_table : 0)
                ? 0
                : t.fk_id_priority ==
                  (!!prioritiesIndex.length ? prioritiesIndex[1].id_table : 0)
                  ? 1
                  : t.fk_id_priority ==
                    (!!prioritiesIndex.length ? prioritiesIndex[2].id_table : 0)
                    ? 2
                    : 3
              ]
            }
            color={
              arrayPriorityColor[
              t.fk_id_priority ==
                (prioritiesIndex.length ? prioritiesIndex[0].id_table : 0)
                ? 0
                : t.fk_id_priority ==
                  (prioritiesIndex.length ? prioritiesIndex[1].id_table : 0)
                  ? 1
                  : t.fk_id_priority ==
                    (prioritiesIndex.length ? prioritiesIndex[2].id_table : 0)
                    ? 2
                    : 3
              ]
            }
          />
        ),
        grid_complexity: (
         <Tag
           label={
            arrayComplexity[
            t.fk_id_complexity ==
              (!!complexities.length ? complexities[0].id_table : 0)
              ? 0
              : t.fk_id_complexity ==
                (!!complexities.length ? complexities[1].id_table : 0)
                ? 1
                : t.fk_id_complexity ==
                  (!!complexities.length ? complexities[2].id_table : 0)
                  ? 2
                  : 3
            ]
          }
           color={
               arrayPriorityColor[
                  t.fk_id_complexity ==
                  (complexities.length ? complexities[0].id_table : 0)
                  ? 0
                  : t.fk_id_complexity ==
                     (complexities.length ? complexities[1].id_table : 0)
                     ? 1
                     : t.fk_id_complexity ==
                        (complexities.length ? complexities[2].id_table : 0)
                        ? 2
                        : 3
               ]
           }
         />
        ),
        grid_status: (
          <div className="d-flex align-items-center" style={{ width: 75 }}>
            <Progress
              current={t.grid_status}
              hiddenText
              background="var(--primary)"
              style={{ height: 7, width: '100%' }}
            />
            <span className="ml-2 status" style={{ fontSize: 10 }}>
              {t.grid_status}%
            </span>
          </div>
        ),

        grid_date: t.dt_cad_request
          ? moment(t.dt_cad_request).format('DD/MM/YYYY')
          : moment(t.grid_date).format('DD/MM/YYYY'),
        grid_hour: t.dt_cad_request
          ? moment(t.dt_cad_request).format('HH:mm')
          : t.grid_hour,
        grid_reopened_count: t.grid_reopened_count,
        grid_date_f:
          t.progress == 100 && moment(t.grid_date_f).format('DD/MM/YYYY'),
        grid_hour_f: t.grid_hour_f,
        grid_id: t.grid_id,
        grid_id_integration: t.id_integration,
        grid_company: t.grid_company,
        grid_location: t.grid_location,
        grid_user: (
          <div>
            {t.vipuser ? (
              <Icon
                color="primary"
                style={{ fontSize: 16, marginBottom: -4 }}
              >
                star
              </Icon>
            ) : null}
            <label>{t.grid_user}</label>
          </div>
        ),

        grid_subject: t.grid_subject,

        grid_tech_group: t.grid_tech_group,
        grid_tech_solicitant: t.techsol,
        grid_service_technician: t.nametech,
        grid_channel: translateFromPtBR(t.channel, language),
        grid_priority: (
          <Tag
            label={
              arrayPriority[
              t.fk_id_priority ==
                (!!priorities.length ? priorities[0].id_table : 0)
                ? 0
                : t.fk_id_priority ==
                  (!!priorities.length ? priorities[1].id_table : 0)
                  ? 1
                  : t.fk_id_priority ==
                    (!!priorities.length ? priorities[2].id_table : 0)
                    ? 2
                    : 3
              ]
            }
            color={
              arrayPriorityColor[
              t.fk_id_priority ==
                (priorities.length ? priorities[0].id_table : 0)
                ? 0
                : t.fk_id_priority ==
                  (priorities.length ? priorities[1].id_table : 0)
                  ? 1
                  : t.fk_id_priority ==
                    (priorities.length ? priorities[2].id_table : 0)
                    ? 2
                    : 3
              ]
            }
          />
        ),
        gird_complexity: (
         <Tag
           label={
             arrayComplexity[
             t.fk_id_complexity ==
               (!!complexities.length ? complexities[0].id_table : 0)
               ? 0
               : t.fk_id_complexity ==
                 (!!complexities.length ? complexities[1].id_table : 0)
                 ? 1
                 : t.fk_id_complexity ==
                   (!!complexities.length ? complexities[2].id_table : 0)
                   ? 2
                   : 3
             ]
           }
           color={
             arrayPriorityColor[
             t.fk_id_complexity ==
               (complexities.length ? complexities[0].id_table : 0)
               ? 0
               : t.fk_id_complexity ==
                 (complexities.length ? complexities[1].id_table : 0)
                 ? 1
                 : t.fk_id_complexity ==
                   (complexities.length ? complexities[2].id_table : 0)
                   ? 2
                   : 3
             ]
           }
         />
       ),
        grid_sla: params[0]?.sla_params ? '' : t.sla_task + 'h',
        grid_sla_time: params[0]?.sla_params ? (
          t.sla && t.sla.indexOf('-') == 4 ? (
            moment(t.sla).format('DD/MM/YY HH:mm:ss')
          ) : t.sla && t.sla.indexOf('-') == 2 ? (
            t.sla
          ) : (
            ' '
          )
        ) : t.status === 'Resolved' || t.status === 'Closed' ? (
          <div style={{ width: 80 }}>
            <div
              style={{
                width: 10,
                height: 10,
                background: TicketUtils.stopSlaTime(t.time_total, t.sla_task)
                  .color,
                borderRadius: '50%',
                float: 'left',
                marginRight: 5,
              }}
            />
            <span
              style={{
                color: TicketUtils.stopSlaTime(t.time_total, t.sla_task)
                  .color,
              }}
              className="sla_time"
            >
              {t.time_total}{' '}
            </span>
          </div>
        ) : (
          <Sla
            statusAwaiting={{ sla: t.sla_time }}
            t={t}
            stopSlaParams={stopSlaParams}
            horas={horas}
            req={req}
            language={language}
            statusAwaitingObj={statusAwaiting}
          />
        ),

        grid_waiting: t.stop_ticket > 0 ? language.WAITING : translateFromPtBR(t.grid_waiting, language),
        grid_reason_status: t.stop_ticket > 0 ? translateFromPtBR(t.grid_waiting, language) : '',
        department: t.department,
        grid_category: t.grid_category,
        grid_catalog_service: t.grid_catalog_service,
        grid_catalog_task: translateFromPtBR(t.grid_catalog_task, language),
        grid_time_spent: t.grid_time_spent,

        grid_correlation_display: t.correlation_display,
        /*grid_observation: t.observation
          ? t.observation.replace(/<\/?[^>]+(>|$)/g, '')
          : '',*/
      };
      if (store.getUserLoged().integration == false) {
        delete dados.grid_id_integration;
      }

      if (params[0]?.sla_params) {
        delete dados.grid_sla
      }
      return dados;
  })}, [tickets, ticketsArray, params, language, prioritiesIndex]);

  useEffect(() => {
    const data = {
      filterStatus: filterStatus,
      filterColumns: filterColumns,
      currentPage: currentPage,
      totalPerPage: totalPerPage,
      typeTicketFilter: typeTicketFilter.filter((item) => item !== null),
      filterGroupTech: filterGroupTech,
      sortBy: sortBy,
      sortByDesc: sortByDesc,
      filterFind: filterFind,
    };

    try {
      const params = JSON.parse(
        localStorage.getItem('paramsTicketManagement')
      );

      let doUpdate = false;

      if( firstLoad ) {
        if( params ) {

          const normalizedTypeTicketFilter =
            Array.isArray(params?.typeTicketFilter) ?
              params?.typeTicketFilter :
              [params?.typeTicketFilter];

          if (
            JSON.stringify(params?.filterStatus) === JSON.stringify(filterStatus) &&
            JSON.stringify(params?.filterColumns) === JSON.stringify(filterColumns) &&
            JSON.stringify(params?.currentPage) === JSON.stringify(currentPage) &&
            JSON.stringify(params?.totalPerPage) === JSON.stringify(totalPerPage) &&
            (
              normalizedTypeTicketFilter.every((item) => typeTicketFilter.includes(item)) &&
              typeTicketFilter?.every((item) => normalizedTypeTicketFilter.includes(item))
            ) &&
            JSON.stringify(params?.filterGroupTech) === JSON.stringify(filterGroupTech) &&
            JSON.stringify(params?.sortBy) === JSON.stringify(sortBy) &&
            JSON.stringify(params?.sortByDesc) === JSON.stringify(sortByDesc) &&
            JSON.stringify(params?.filterFind) === JSON.stringify(filterFind)
          ) {
            doUpdate = true;
          }

          if (doUpdate == true) {
            setFirstLoad(false);
            refreshGrid();
          }
        } else {
          setFirstLoad(false);
          refreshGrid();
        }
      } else if( !firstLoad || autoloadTickets ) {
        if (
          JSON.stringify(params?.filterStatus) === JSON.stringify(filterStatus)
        ) {
          doUpdate = true;
        }
        if (
          JSON.stringify(params?.filterColumns) === JSON.stringify(filterColumns)
        ) {
          doUpdate = true;
        }
        if (
          JSON.stringify(params?.currentPage) === JSON.stringify(currentPage)
        ) {
          doUpdate = true;
        }
        if (
          JSON.stringify(params?.totalPerPage) === JSON.stringify(totalPerPage)
        ) {
          doUpdate = true;
        }
        if (JSON.stringify(params?.typeTicketFilter) === JSON.stringify(typeTicketFilter)) {
          doUpdate = true;
        }
        if (
          JSON.stringify(params?.filterGroupTech) ===
          JSON.stringify(filterGroupTech)
        ) {
          doUpdate = true;
        }
        if (JSON.stringify(params?.sortBy) === JSON.stringify(sortBy)) {
          doUpdate = true;
        }
        if (JSON.stringify(params?.sortByDesc) === JSON.stringify(sortByDesc)) {
          doUpdate = true;
        }
        if (JSON.stringify(params?.filterFind) === JSON.stringify(filterFind)) {
          doUpdate = true;
        }

        if (doUpdate == true) {
          refreshGrid();
        }
      }
    } catch (e) { }
  }, [
    filterStatus,
    filterColumns,
    currentPage,
    totalPerPage,
    typeTicketFilter,
    filterGroupTech,
    sortBy,
    sortByDesc,
    filterFind,
    autoloadChange,
  ]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filterStatus, typeTicketFilter, filterGroupTech]);

  async function refreshGrid(force = false) {
      store.spinner = true;

      const statusFilter = filterStatus
        .filter((item) => item.value !== 'Todos' && item.value !== 'Job')
        .map((item) => item.value);

      const groupTech = filterGroupTech?.group_users;

      const job = !!filterStatus.find((item) => item.value === 'Job');

      let values = {
        filter_user_group: store.getUserLoged().id,
        statusFilter,
        job,
        typeTickets: typeTicketFilter,
        groupTech,
        filterColumns,
        sortBy,
        sortByDesc,
        filterFind,
        language: language.LOCATION_LANGUAGE,
      };

      action
        .execute('post', `${APIEndpoints.TICKET}/ticketFindManagement?limit=${totalPerPage}&offset=${currentPage !== -1 ? currentPage : 0}`, '', values)
        .then((res) => {
          setTotalTickets(res.totalCount);
          setTickets([...res.data]);
          store.spinner = false;

          localStorage.setItem(
            'paramsTicketManagement',
            JSON.stringify({
              filterStatus,
              filterColumns,
              filterFind,
              currentPage,
              totalPerPage,
              filterGroupTech,
              typeTicketFilter,
              autoloadTickets,
              sortBy,
              sortByDesc,
              filterFind,
            })
          );
        })
        .catch((error) => {
          store.spinner = false;
        });
  }

  const updateAutoloadParams = (autoloadTickets) => {
    try {
      let params = JSON.parse(localStorage.getItem('paramsTicketManagement'));
      params.autoloadTickets = autoloadTickets;
      localStorage.setItem('paramsTicketManagement', JSON.stringify(params));
    } catch (e) { }
  };
  let waitingTickets = [];

  statusAwaiting.forEach((status) => {
    waitingTickets.push(status.status);
  });

  if (inte == false) {
    columns = columns.filter((b) => b.key != 'grid_id_integration');
  }

  if (params[0]?.sla_params) {
    columns = columns.filter((b) => params[0]?.sla_params && b.key !== 'grid_sla');
  }

  return (
    <PageLayout
      title={language.TICKET_MANAGEMENT}
      subTitle={language.DASHBOARD_PAGE.DM + '/'}
      back
      rightElements={[
        <Button
          onClick={() => {
            setAutoloadTickets(!autoloadTickets);
            updateAutoloadParams(!autoloadTickets);
          }}
          icon="fas fa-sync"
          label={`${autoloadTickets == true ? language.DISABLE : language.ENABLE
            }`}
          className="btn-item"
          color={'primary'}
          variant="normal"
          tooltipLabel={`${autoloadTickets == true
            ? language.DISABLE_AUTOMATIC_LOADING
            : language.ENABLE_AUTOMATIC_LOADING
            }`}
          style={{ marginTop: '1rem', marginRight: '1rem' }}
        />,
        store.getUserLoged().integration == true ? (
          <IntegrationDetail
            APIEndpoints={APIEndpoints}
            store={store}
          ></IntegrationDetail>
        ) : (
          <span></span>
        ),
        <Button
          onClick={() => redirect('/dash/techtask')}
          label={language.TASKS_LIST}
          className="btn-item"
          color="warning"
          variant="normal"
          style={{ marginTop: '1rem', marginRight: '1rem' }}
        />,
        (store.getUserLoged().perfil.gsd_categorization == false) ? (
          <i></i>
        ) : (
          <Button
            onClick={() => redirect('/dash/request')}
            label={`${language.TICKETS_TO_CATEGORIZE}: ${requests ? requests : 0
              }`}
            className="btn-item"
            color="warning"
            variant="normal"
            style={{ marginTop: '1rem', marginRight: '1rem' }}
          />)
        ,
        <div
          className="d-flex align-items-center _btn-group"
          style={{ marginTop: '1rem' }}
        >
          <Button
            onClick={() => {
              setTypeTicketFilter((prev) => {
                const cleanedPrev = prev.filter((item) => item !== null);

                if (cleanedPrev.includes(4)) {
                  return cleanedPrev.filter((item) => item !== 4);
                } else {
                  return [...cleanedPrev, 4];
                }
              });
              setticketsArray([])
              setTicketsDescArray([])
              setTicketsTypeTicketArray([])
            }}
            icon="fas fa-user-plus"
            label={language.REQUEST}
            className="btn-item"
            outlined={!typeTicketFilter.includes(4)}
            color={typeTicketFilter.includes(4) ? 'warning' : 'primary'}
            variant="normal"
          />
          <Button
            onClick={() => {
              setTypeTicketFilter((prev) => {
                const cleanedPrev = prev.filter((item) => item !== null);

                if (cleanedPrev.includes(1)) {
                  return cleanedPrev.filter((item) => item !== 1);
                } else {
                  return [...cleanedPrev, 1];
                }
              });
              setticketsArray([])
              setTicketsDescArray([])
              setTicketsTypeTicketArray([])
            }}
            icon="fas fa-bell"
            label={language.TICKET_TYPES.INCIDENT}
            className="btn-item"
            outlined={!typeTicketFilter.includes(1)}
            color={typeTicketFilter.includes(1) ? 'warning' : 'primary'}
            variant="normal"
          />
          <Button
            onClick={() => {
              setTypeTicketFilter((prev) => {
                const cleanedPrev = prev.filter((item) => item !== null);

                if (cleanedPrev.includes(2)) {
                  return cleanedPrev.filter((item) => item !== 2);
                } else {
                  return [...cleanedPrev, 2];
                }
              });
              setticketsArray([])
              setTicketsDescArray([])
              setTicketsTypeTicketArray([])
            }}
            icon="fas fa-exclamation-triangle"
            style={{ borderLeft: 0 }}
            label={language.TICKET_TYPES.PROBLEM}
            className="btn-item"
            outlined={!typeTicketFilter.includes(2)}
            color={typeTicketFilter.includes(2) ? 'warning' : 'primary'}
            variant="normal"
          />
          {generalParams.enable_event_ticket_type && (
            <Button
              onClick={() => {
                setTypeTicketFilter((prev) => {
                  const cleanedPrev = prev.filter((item) => item !== null);

                  if (cleanedPrev.includes(5)) {
                    return cleanedPrev.filter((item) => item !== 5);
                  } else {
                    return [...cleanedPrev, 5];
                  }
                });
              }}
              icon="fas fa-clipboard"
              style={{ borderLeft: 0 }}
              label={language.TICKET_TYPES.EVENT}
              className="btn-item"
              outlined={!typeTicketFilter.includes(5)}
              color={typeTicketFilter.includes(5) ? 'warning' : 'primary'}
              variant="normal"
            />
          )}
          {generalParams.enable_crisis_ticket_type && (
            <Button
              onClick={() => {
                setTypeTicketFilter((prev) => {
                  const cleanedPrev = prev.filter((item) => item !== null);

                  if (cleanedPrev.includes(6)) {
                    return cleanedPrev.filter((item) => item !== 6);
                  } else {
                    return [...cleanedPrev, 6];
                  }
                });
              }}
              icon="fas fa-arrow-down"
              style={{ borderLeft: 0 }}
              label={language.TICKET_TYPES.CRISIS}
              className="btn-item"
              outlined={!typeTicketFilter.includes(6)}
              color={typeTicketFilter.includes(6) ? 'warning' : 'primary'}
              variant="normal"
            />
          )}
          {generalParams.enable_critical_incident_ticket_type && (
            <Button
              onClick={() => {
                setTypeTicketFilter((prev) => {
                  const cleanedPrev = prev.filter((item) => item !== null);

                  if (cleanedPrev.includes(7)) {
                    return cleanedPrev.filter((item) => item !== 7);
                  } else {
                    return [...cleanedPrev, 7];
                  }
                });
                setticketsArray([])
                setTicketsDescArray([])
                setTicketsTypeTicketArray([])
              }}
              icon="fas fa-exclamation-circle"
              style={{ borderLeft: 0 }}
              label={language.TICKET_TYPES.CRITICAL_INCIDENT}
              className="btn-item"
              outlined={!typeTicketFilter.includes(7)}
              color={typeTicketFilter.includes(7) ? 'warning' : 'primary'}
              variant="normal"
            />
          )}
          <CommonForm
            individual
            fields={[
              {
                type: 'element',
                content: (v, h, submit) => (
                  <Button
                    icon="fas fa-eraser"
                    variant="circle"
                    color="primary"
                    label={language.CLEAR}
                    onClick={() => cleanFilters()}
                    style={{ marginTop: 2, marginLeft: 10 }}
                  />
                ),
              },
            ]}
          />
        </div>,
      ]}
    >
      <SearchForm
        setAdvancedSearchModalOpen={setAdvancedSearchModalOpen}
        advancedSearchModalOpen={advancedSearchModalOpen}
        onSubmitAdvancedSearch={onSubmitAdvancedSearch}
        APIEndpoints={APIEndpoints}
        action={action}
      />
      {openDialog == true ? (
        <DialogForm
          store={store}
          onFormSubmitTab={onFormSubmitTab}
          open={openDialog}
          APIEndpoints={APIEndpoints}
          action={action}
          values={{
            subject: '',
            description: '',
            fk_id_user: 0,
            fk_id_request: null,
            fk_id_company: 0,
          }}
          onClose={() => setOpenDialog(false)}
        />
      ) : null}

      <ControllerTicket
        controlButtonTheme={TICKETS_THEME.controlButton}
        closeDetail={closeDetail}
        abas={abas}
        setLocalFiles={function () { }}
        handleDetail={handleDetail}
      />
      <div id="TicketManagementList" style={{ marginTop: '0px', height: '200px' }}>
        <CommonTable
          isResponsiveTicket
          searchColumn
          freezeHead
          tableId={'ticket_management_list'}
          allowSelectColumns={true}
          onClearAllFilter={cleanFilters}
          searchValue={filterFind}
          placeholderSearch={language.SEARCH_BY_ID + '...'}
          searchColumnEvent={searchId}
          currentPage={currentPage}
          countTotal={totalTickets}
          newSla={params ? params[0]?.sla_params : false}
          isAutomaticPagination={false}
          loadSearch={true}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          AppBarProps={{
            dark: true,
            className: 'py-0 pr-0',
          }}
          excel={sendvalues}
          pdf={sendValuesPdf}
          // sh={sendvaluesSearch}
          print={false}
          paginationTop={false}
          beforeBar2={
            <div className="content-item-control d-flex align-items-center">
              {statusTicketFilter.map((status, i) => (
                <a
                  href="#"
                  style={{
                    backgroundColor: filterStatus.find(
                      (item) => item.value === status.value
                    )
                      ? 'var(--warning)'
                      : 'transparent',
                  }}
                  onClick={async () => {
                    if (status.value === 'Todos' || status.value === 'Job') {
                      setFilterStatus([status]);
                    } else {
                      if (
                        filterStatus.find((item) => item.value === status.value)
                      ) {
                        setFilterStatus([
                          ...filterStatus.filter(
                            (item) => item.value !== status.value
                          ),
                        ]);
                      } else {
                        setFilterStatus([
                          ...filterStatus.filter(
                            (item) =>
                              item.value !== 'Todos' && item.value !== 'Job'
                          ),
                          status,
                        ]);
                      }
                    }
                    setticketsArray([])
                    setTicketsDescArray([])
                    setTicketsTypeTicketArray([])
                  }}
                >
                  <span>{status.label}</span>
                </a>
              ))}
            </div>
          }
          filterColumns={filterColumns}
          beforeBar={[
            ticketsArray.length > 0 ? (
              <Button
                onClick={() => setModalClose(true)}
                variant="circle"
                label={language.EDIT_TICKETS}
                className="mr-3"
                icon="far fa-share-square"
                color="warning"
              />
            ) : null,

            <Button
              variant="circle"
              icon="fas fa-sync"
              color="warning"
              className="mr-3"
              onClick={() => refreshGrid()}
              tooltipLabel={language.REFRESH_PAGE}
            />,
            (user.role != 'solicitant' ||
              store.getUserLoged().open_dash_solicitant == true) &&
              params &&
              params[0] &&
              params[0].disabled_new_ticket != true ? (
              <Button
                variant="circle"
                icon="fas fa-plus"
                color="warning"
                className="mr-3"
                label={language.ADD}
                onClick={() => setOpenDialog(true)}
              />
            ) : (
              <i></i>
            ),

            <Button
              variant="circle"
              icon="fas fa-search"
              color="warning"
              label={language.ADVANCED_SEARCH}
              className="mr-3"
              onClick={() => setAdvancedSearchModalOpen(true)}
            />,
          ]}
          mini
          col={columns}
          noSort={['sla_time', 'status']}
          onSortItem={(item, sortByStatus) => {
            setSortBy(item);
            setSortByDesc(sortByStatus);
          }}
          data={datas}
        />
        <Menu id="long-menu" anchorEl={anchorEl} open={open}>
          <MenuItem></MenuItem>
        </Menu>

        {modalClose == true ? (
          <Dialog
            style={{ height: '250px' }}
            title={language.EDIT_TICKETS}
            open={true}
            onClose={() => {
              setModalClose(false)
              setticketsArray([])
              setTicketsDescArray([])
              setTicketsTypeTicketArray([])
            }}
            maxWidth="lg"
          >
            {user.role != 'solicitant' ? (
              <EditMultipleTickets
                APIEndpoints={APIEndpoints}
                action={action}
                language={language}
                loadNormal={refreshGrid}
                ticket={ticket}
                onNotesTicket={onNotesTicketTab}
                ticketsArray={ticketsArray}
                ticketsDescArray={ticketsDescArray}
                ticketsTypeTicketArray={ticketsTypeTicketArray}
                setTicketsDescArray={setTicketsDescArray}
                setTicketsTypeTicketArray={setTicketsTypeTicketArray}
                setticketsArray={setticketsArray}
                answer={answer}
                desc={desc}
                setModalClose={setModalClose}
                setDesc={setDesc}
                user={store.getUserLoged()}
                toggleAlert={store.toggleAlert}
                type_tickets={ticket.type_tickets}
                onChangeStatus_Tab={onChangeStatus_Tab}
                store={store}
                params={params}
                onCommentTicket={onCommentTicket}
              />
            ) : null}
          </Dialog>
        ) : null}
      </div>
    </PageLayout>
  );
}