import React, {
  Component,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@common/Dialog';
import CommonTable from '@common/CommonTable';
import Store from '@data/Store';
import { Tooltip } from 'react-tippy';
import { Checkbox } from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import Actions from '@data/Actions';
import { translateFromPtBR } from '../languages/Dictionary';

const action = new Actions();
const { language_type } = Store;

const AutocompleteSelect = (props) => {
  let [options_, setOptions] = useState([]);
  const { language } = Store;

  let {
    route,
    idinit,
    textinit,
    values,
    fieldquery,
    fieldvaluedb,
    fieldlabeldb,
    showvip,
    method,
    _value,
    _handleValue,
    f,
    routeAll,
    methodGetAll,
    routeGetCount,
    filter,
    textlabel,
    useinit,
    resetValue,
    showOptionAll,
    showEmptyFilter,
    filterAll,
    errors,
  } = props;

  let [value, setValue] = useState({});
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [allData, setAllData] = useState([]);
  const [showAllData, setShowAllData] = useState(false);
  const [countTotal, setCountTotal] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState(textinit);
  const [searchValue, setSearchValue] = useState({});
  const [editvalues, setEditvalues] = useState([]);
  const [initialFilterAll, setInitialFilterAll] = useState([]);
  const [showLabel, setShowLabel] = useState(textinit);
  const [itensSelected, setItensSelected] = useState([]);
  const [vipMessage, setVipMessage] = useState(false);

  useEffect(() => {
    if (selectedValue != undefined) {
      _handleValue(selectedValue, f.name);
      if (f.fromCommonTable) {
        _handleValue({ index: f.index, label: selectedLabel }, f.name + '_');
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (_value !== undefined) {
      setValue(_value);
    }
  }, [_value]);

  useEffect(() => {
    _value && setEditvalues([]);
  }, [resetValue]);

  useEffect(() => {
    if (
      f.isMulti == true &&
      Array.isArray(textinit) == true &&
      Array.isArray(idinit) == true
    ) {
      let op = [];
      for (let i = 0; i < textinit.length; i++) {
        op.push({ label: textinit[i], value: idinit[i] });
      }

      setItensSelected(op);
      setEditvalues(op);
    } else {
      setOptions([{ label: textinit, value: idinit }]);
      setValue(idinit);
      setSelectedValue(idinit);
      setSelectedLabel(textinit);
      setShowLabel(textinit);
    }
    search('init');
  }, [textinit]);

  useEffect(() => {
    if (JSON.stringify(initialFilterAll) != JSON.stringify(filterAll)) {
      search('init');
      setInitialFilterAll(filterAll);
    }
  }, [filterAll]);

  useEffect(() => {
    if (f.cleanValue == true) {
      setSelectedLabel(textinit);
      setShowLabel(textinit);
      setSelectedValue(idinit);
      setValue(idinit);
      setEditvalues([]);
    }
  }, [f.cleanValue]);

  const search = (text, onFocus) => {
    let oo = { [fieldquery]: text, ...filterAll };
    if (text == 'init' || onFocus == true) {
      if (
        (f.loadOnFocus && onFocus == true) ||
        (!f.loadOnFocus && text == 'init')
      ) {
        oo = { [fieldquery]: '', ...filterAll };

        action
          .execute(method ? method : 'post', `${routeAll}offset=${0}&limit=${25}`, '', oo)
          .then((data) => {
            if( data.data?.data ) {
              data = data.data?.data;
            } else if( data.data ) {
              data = data.data;
            }

            let itens = [];
            if (showOptionAll) {
              itens.push({
                value: 0,
                label: `-------${language.ALL}---------`,
              });
            }
            if (f.showOptionGlobal) {
              itens.push({
                value: 0,
                label: `-------${language.GLOBAL}---------`,
              });
            }
            if (showEmptyFilter) {
              itens.push({ value: 'empty', label: `` });
            }

            let data_ = data.map((v) => {
              const label =
                fieldlabeldb && fieldlabeldb === "name" && v[fieldlabeldb]
                  ? ( showvip && v.vip ? '★ ' : '' ) + v[fieldlabeldb]
                  : v.description
                    ? (language_type.includes('PT') ? v.description : language_type.includes('EN') ? v.en_description : v.es_description)
                    : f.showEmail
                      ? ( showvip && v.vip ? '★ ' : '' ) + v[fieldlabeldb] + ' - ' + v.email
                      : f.showId
                        ? v[f.idFieldToShow] + ' - ' + v[fieldlabeldb]
                        : v[fieldlabeldb];

              return {
                label: f.translateOptions ? translateFromPtBR(label, language) : label,
                value: v[fieldvaluedb],
              }
            });
            itens.push(...data_);
            setOptions(itens);
          });
      }
    } else if (text.length >= 3) {
      action
        .execute(method, routeAll, '', oo)
        .then((data) => {
          if( data.data?.data ) {
            data = data.data?.data;
          } else if( data.data ) {
            data = data.data;
          }

          let itens = [];
          if (showOptionAll) {
            itens.push({ value: 0, label: `-------${language.ALL}---------` });
          }

          if (showEmptyFilter) {
            itens.push({ value: 'empty', label: `` });
          }

          let data_ = data.map((v) => {
            const label = f.showEmail
              ? ( showvip && v.vip ? '★ ' : '' ) + v[fieldlabeldb] + ' - ' + v.email
              : f.showId
                ? ( showvip && v.vip ? '★ ' : '' ) + v[f.idFieldToShow] + ' - ' + v[fieldlabeldb]
                : ( showvip && v.vip ? '★ ' : '' ) + v[fieldlabeldb];
            return {
              label: f.translateOptions ? translateFromPtBR(label, language) : label,
              value: v[fieldvaluedb],
              vip: showvip && v.vip ? v.vip : false
            }
          });
          itens.push(...data_);

          setOptions(itens);

          if (itens.length == 0) {
            setTimeout(() => {
              search('init', true)
            }, 2000);
          }
        });
    }
  };

  const showAll = () => {
    setShowAllData(true);
    action
      .execute('post', routeGetCount, '', filterAll)
      .then((data) => {
        if( data.data?.data ) {
          data = data.data?.data;
        } else if( data.data ) {
          data = data.data;
        }
        if( data.count ) {
          setCountTotal(data.count);
        } else if( data.data ) {
          setCountTotal(data.data.length);
        } else {
          setCountTotal(data.length);
        }
      });
  };

  const saveItensSelected = () => {
    if (itensSelected.length < editvalues.length) {
      setEditvalues([...itensSelected]);
      setSelectedValue([...itensSelected].map((o) => o.value));

      setSelectedLabel([...itensSelected].map((o) => o.label));
    } else {
      const itensSelectedFormated = itensSelected.filter(
        (i) => !editvalues.map((e) => e.value).includes(i.value)
      );
      setEditvalues([...editvalues, ...itensSelectedFormated]);

      setSelectedValue(
        [...editvalues, ...itensSelectedFormated].map((o) => o.value)
      );

      setSelectedLabel(
        [...editvalues, ...itensSelectedFormated].map((o) => o.label)
      );
    }

    setShowAllData(false);
  };

  const saveItem = (item) => {
    setOptions([{ label: item[fieldlabeldb], value: item[fieldvaluedb] }]);
    if (f.isMulti != true) {
      setSelectedValue(item[fieldvaluedb]);
      setSelectedLabel(item[fieldlabeldb]);

      if( showvip && item.vip ) {
        setVipMessage(item.vip);
      } else {
        setVipMessage(false);
      }
    }
    let label =
      fieldlabeldb && fieldlabeldb === 'name' && item[fieldlabeldb] ?
        ( showvip && item.vip ? '★ ' : '' ) + item[fieldlabeldb] :
          (item.description && (language_type.includes('PT') ?
            item.description :
              language_type.includes('EN') ?
                item.en_description :
                  item.es_description)) || ( showvip && item.vip ? '★ ' : '' ) + item[fieldlabeldb];
    setShowLabel(label);
    if (f.isMulti == true) {
      setEditvalues([
        ...editvalues,
        { label: item[fieldlabeldb], value: item[fieldvaluedb] },
      ]);

      setSelectedValue(
        [
          ...editvalues,
          { label: item[fieldlabeldb], value: item[fieldvaluedb] },
        ].map((o) => o.value)
      );
    }

    setShowAllData(false);
  };
  useEffect(() => {
    if (showAllData) {
      callPageSelectFilter();
    }
  }, [showAllData]);

  const callPageSelectFilter = (page = 0, items = {}, totalPerPage = 100) => {
    setSearchValue(items);
    let limit = totalPerPage || 100;

    let offset = page * limit;

    const isEmpty = Object.keys(items).length === 0;
    if (!isEmpty) {
      offset = 0;
    }

    action
      .execute(methodGetAll, `${routeAll}offset=${offset}&limit=${limit}`, '', { ...items, ...filterAll })
      .then((data) => {
        if( data.data?.data ) {
          data = data.data?.data;
        } else if( data.data ) {
          data = data.data;
        }
        setAllData(data);
      });

    action
      .execute('post', routeGetCount, '', { ...items, ...filterAll })
      .then((data) => {
        if( data.data?.data ) {
          data = data.data?.data;
        } else if( data.data ) {
          data = data.data;
        }

        if (data.count !== undefined || (data[0] && data[0].count !== undefined)) {
          setCountTotal(data.count ?? data[0].count);
        } else if (data.data && Array.isArray(data.data)) {
          setCountTotal(data.data.length);
        } else if (Array.isArray(data)) {
          setCountTotal(data.length);
        }
      });
  };

  const saveOption = (option) => {
    if (f.isMulti) {
      let values = option.map((o) => o.value);
      let labels = option.map((o) => o.label);

      setSelectedLabel(labels);
      setSelectedValue(values);
      setEditvalues(option);

      if (option.length < editvalues.length) {
        setItensSelected([...option]);
      } else {
        const itens = option.filter(
          (f) => !editvalues.map((c) => c.value).includes(f.value)
        );
        setItensSelected([...itens, ...editvalues]);
      }
    } else {
      setSelectedLabel(option.label);
      setSelectedValue(option.value);

      if( showvip && option.vip ) {
        setVipMessage(option.vip);
      } else {
        setVipMessage(false);
      }
    }
  };

  const handleSelectItensTable = (item, checked) => {
    if (checked == true) {
      let itens = itensSelected;
      item = { label: item[fieldlabeldb], value: item[fieldvaluedb] };
      itens.push(item);
      setItensSelected([...itens]);
    } else {
      let itens = itensSelected.filter((i) => i.value !== item[fieldvaluedb]);
      setItensSelected([...itens]);
    }
  };

  const filterOptionsMulti = (options) => {
    const optionsFilter = options.filter(
      (f) => !editvalues.map((c) => c.value).includes(f.value)
    );
    return optionsFilter;
  };

  let col = !f.isMulti
    ? [
        { key: 'name', label: language.NAME },
        { key: 'save', label: language.SAVE },
      ]
    : [
        { key: 'check', label: language.SELECT },
        { key: 'name', label: language.NAME },
      ];

  let dataTable = allData.data
    ? allData.data.map((d) => {
        let data = {};
        if (!f.isMulti) {
          data.name = 
            fieldlabeldb && d[fieldlabeldb] 
              ? (showvip && d.vip ? '★ ' : '') + d[fieldlabeldb]
              : d.description
              ? language_type.includes('PT') 
                ? d.description 
                : language_type.includes('EN') 
                  ? d.en_description 
                  : d.es_description
              : d.name
              ? (f.showEmail 
                  ? (showvip && d.vip ? '★ ' : '') + d.name + ' - ' + d.email 
                  : (showvip && d.vip ? '★ ' : '') + d.name)
              : d.value;
          data.save = !f.disabled ? (
            <div>
              <IconButton
                size="small"
                className={'icon-search'}
                aria-label="Menu"
                onClick={() => saveItem(d)}
              >
                <CheckIcon />
              </IconButton>
            </div>
          ) : null;
        } else {
          data.check = (
            <Checkbox
              checked={!!itensSelected.find((i) => i.value == d[fieldvaluedb])}
              onChange={(evt) => handleSelectItensTable(d, evt.target.checked)}
            />
          );
          data.name = 
            fieldlabeldb && d[fieldlabeldb] 
              ? (showvip && d.vip ? '★ ' : '') + d[fieldlabeldb]
              : d.description
              ? language_type.includes('PT') 
                ? d.description 
                : language_type.includes('EN') 
                  ? d.en_description 
                  : d.es_description
              : d.name
              ? (f.showEmail 
                  ? (showvip && d.vip ? '★ ' : '') + d.name + ' - ' + d.email 
                  : d.name)
              : d.value;
          if (f.customFields) {
            f.customFields.forEach((c) => {
              data[c.value] = d[c.value];
            });
          }
        }
        return data;
      })
    : !f.isMulti
    ? allData.map((d) => ({
        name: 
          fieldlabeldb && d[fieldlabeldb]
            ? (showvip && d.vip ? '★ ' : '') + d[fieldlabeldb] + 
              (d.description 
                ? (language_type.includes('PT') 
                    ? ' - ' + d.description 
                    : language_type.includes('EN') 
                      ? ' - ' + d.en_description 
                      : ' - ' + d.es_description)
                : '')
            : d.description
            ? (language_type.includes('PT') 
                ? d.description 
                : language_type.includes('EN') 
                  ? d.en_description 
                  : d.es_description)
            : d.name
            ? (f.showEmail 
                ? (showvip && d.vip ? '★ ' : '') + d.name + ' - ' + d.email 
                : d.name)
            : d.value,
        save: !f.disabled ? (
          <div>
            <IconButton
              size="small"
              className={'icon-search'}
              aria-label="Menu"
              onClick={() => saveItem(d)}
            >
              <CheckIcon />
            </IconButton>
          </div>
        ) : null,
      }))
    : allData.map((d) => {
        let data = {
          check: (
            <Checkbox
              checked={!!itensSelected.find((i) => i.value == d[fieldvaluedb])}
              onChange={(evt) => handleSelectItensTable(d, evt.target.checked)}
            />
          ),
          name: 
            fieldlabeldb && d[fieldlabeldb]
              ? (showvip && d.vip ? '★ ' : '') + d[fieldlabeldb] + 
                (d.description 
                  ? (language_type.includes('PT') 
                      ? ' - ' + d.description 
                      : language_type.includes('EN') 
                        ? ' - ' + d.en_description 
                        : ' - ' + d.es_description)
                  : '')
              : d.description
              ? (language_type.includes('PT') 
                  ? d.description 
                  : language_type.includes('EN') 
                    ? d.en_description 
                    : d.es_description)
              : d.name
              ? (showvip && d.vip 
                  ? '★ ' 
                  : '') + d.name + 
                (f.showEmail 
                  ? ' - ' + d.email 
                  : '')
              : d.value,
        };
        if (f.customFields) {
          f.customFields.forEach((c) => {
            data[c.value] = d[c.value];
          });
        }
        return data;
      });

  if (f.customFields) {
    col.push(...f.customFields.map((c) => ({ key: c.value, label: c.label })));
  }

  return (
    <div
      className={
        f.isMulti == true ? 'divAutoComplete' : 'divAutoCompleteSingle'
      }
    >
      {showAllData == true ? (
        <Dialog
          open={showAllData}
          maxWidth="lg"
          title={language.ITEMS_LISTING}
          onClose={() => setShowAllData(false)}
        >
          <CommonTable
            title={language.ITEMS}
            index="UserList"
            countTotal={countTotal}
            isAutomaticPagination={false}
            paginationTop={false}
            rowsPerPageOptions={[100]}
            search={f.nosearch ? false : true}
            beginWithNoFilter={true}
            callPageSelectFilter={(page, values, rowsPerPage, type) =>
              callPageSelectFilter(page, values, rowsPerPage, type)
            }
            data={dataTable}
            col={col}
            searchColumn
            orderColumn
          />
          <CommonForm
            fields={[]}
            button={{ md: 12, label: language.SAVE, primary: true }}
            onSubmit={() => saveItensSelected()}
          />
        </Dialog>
      ) : null}
      <Tooltip
        html={
          <div style={{ color: 'white' }}>
            {language.TYPE_TO_SEARCH_OR_CLICK_MAGNIFYING_GLASS}
          </div>
        }
        disabled={props.disabledTooltip || false}
        arrow={true}
        position="bottom"
        theme="dark"
        style={{ width: '100%' }}
        className="ellipsis"
      >
        {f.isMulti ? (
          <Autocomplete
            disablePortal={!f.disablePortal}
            noOptionsText={language.NO_OPTIONS}
            value={editvalues}
            disableCloseOnSelect
            multiple
            size="small"
            options={options_}
            onChange={(event, newValue) => saveOption(newValue)}
            filterOptions={(option) => filterOptionsMulti(option)}
            getOptionLabel={(option) => option.label}
            className={`autocomplete common-select-min autocompleteMulti`}
            sx={{ width: 100 }}
            id={f.name}
            disabled={f.disabled}
            renderInput={(params) => (
              <TextField
                error={errors[f.name]}
                helperText={errors[f.name] ? language.FILL_THE_FIELD : ''}
                variant="outlined"
                sx={{ padding: 5 }}
                onChange={(g) => {
                  search(g.target.value);
                }}
                onFocus={(evt) => search('f', true)}
                {...params}
                value={selectedLabel}
                label={f.required ? f.textlabel + ' *' : f.textlabel}
              />
            )}
          />
        ) : (
          <Autocomplete
            disablePortal={!f.disablePortal}
            noOptionsText={language.NO_OPTIONS}
            inputValue={showLabel}
            onInputChange={(evt, text) => {
              setShowLabel(text);
            }}
            options={options_}
            onChange={(event, newValue) => {
              if (newValue) {
                saveOption(newValue);
              } else {
                saveOption({ label: '', value: 0 });
              }
            }}
            id={f.name}
            filterOptions={(option) => option}
            getOptionLabel={(option) => option.label}
            className={`autocomplete common-select-min  autocompleteSingle `}
            disabled={f.disabled}
            renderInput={(params) => (
              <TextField
                error={errors[f.name]}
                helperText={errors[f.name] ? language.FILL_THE_FIELD : ''}
                variant="outlined"
                onChange={(g) => {
                  if (g.target.value === '') {
                    saveOption({ label: g.target.value, value: 0 })
                    return _handleValue(0, f.name);
                  }
                  search(g.target.value);
                }}
                onFocus={(evt) => search('f', true)}
                {...params}
                value={selectedLabel}
                label={f.required ? f.textlabel + ' *' : f.textlabel}
              />
            )}
          />
        )}
      </Tooltip>
      <div style={{ maxWidth: '25px', paddingTop: '10px' }}>
        {' '}
        {(!f.disabled && (f.showSearchButtom === undefined || f.showSearchButtom)) && (
          <IconButton
            size="small"
            className={'icon-search'}
            aria-label="Menu"
            onClick={showAll}
            style={{ marginTop: '5px' }}
          >
            <SearchIcon />
          </IconButton>
        )}
      </div>
      {showvip && vipMessage ? (
        <span style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          fontStyle: 'italic',
          fontSize: '12px',
          color: '#bb9702',
          padding: '0 12px',
          marginTop: '4px'
        }}>{language.USER_VIP}</span>
      ) : null}
    </div>
  );
};

export default AutocompleteSelect;
