import React, { Component } from 'react';

import { Tooltip } from 'react-tippy';
import { observer } from 'mobx-react';
import { TextField, MenuItem, Paper } from '@material-ui/core';

import RaisedButton from '@common/RaisedButton';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm';
import AppBar from '@common/AppBar';
import './PerfilFormList.css';
import Fields from './PerfilFormListFields';
import { perfils } from './PerfilOptions';

@observer
export default class PerfilFormList extends Component {
  constructor() {
    super();
  }

  render() {
    let {
      language,
      companies,
      handlePage,
      savePerfil,
      values,
      check,
      setcheck,
    } = this.props;

    const checkOptions = (check) => {
      Object.keys(values).forEach((key) => {        
        if (!['total_count', 'fk_user_id', 'fk_id_company', 'name', 'perfil_id', 'id', 'dt_cad', 'deleted'].includes(key))
        values[key] = check;
      });
    }

    let listCompanies = companies.map((c) => ({
      value: c.id_company,
      label: c.name,
    }));
    let item = '';
    Fields({ language }, listCompanies)
      .form1.filter((y) => y.name != undefined)
      .map((g) => {
        item = item + `ADD COLUMN   ${g.name}  bool NULL DEFAULT false, `;
      });
    if (values == null) {
      values = perfils;
    }

    let but = (
      <RaisedButton
        icon="fas fa-check"
        color={
          check == null
            ? 'primaryGradient'
            : check == false
            ? 'primaryGradient'
            : 'danger'
        }
        label={check ? language.CHECK_ALL : language.DISCHECK_ALL}
        style={{
          fontSize: 13,
        }}
        onClick={() => {
          setcheck(!check)
          checkOptions(check)
        }}
      />
    );

    return (
      <Paper id="PerfilFormList">
        <CommonForm
          language={language}
          fields={Fields({ language }, listCompanies, but).form1}
          values={values}
          onFormSubmit={savePerfil}
          button={{
            md: 12,
            label: language.SAVE,
            color: 'success',
          }}
        />
      </Paper>
    );
  }
}