import React, { Component, Fragment, useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';

import Button from '../../../common/Button';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Constants from '../../../data/Constants';
import Actions from '@data/Actions';
import './index.css';
import Store from '@data/Store';
const action = new Actions();
const { language_type } = Store;

const index = (props) => {
  const { language } = props;
  const [notificationRecipients, setNotificationRecipients] = useState([]);
  const [recipientsPayload, setRecipientsPayload] = useState(null);

  const [loadingRule, setLoadingRule] = useState(false);

  const loadNotificationData = (id) => {
    action
      .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/${id}/signers`, '')
      .then((res) => {
        res = res.data;

        let recipients = res.data.dinamic.map((item) => ({
          section: "dinamic",
          id: item.id,
          recipient: item.value,
          value: item.key,
          type: item.type
        }));

        recipients = recipients.concat(
          res.data.static.map((item) => ({
            section: "static",
            id: item.id,
            recipient: item.value,
            value: item.value,
            type: item.type
          }))
        );

        setNotificationRecipients(recipients);
        setRecipientsPayload(res.data);
        setLoadingRule(false);
      }).catch((error) => {
        console.error(error);
        setLoadingRule(false);
      });
  };

  useEffect(() => {
    if (props.value?.id) {
      setLoadingRule(true);
      loadNotificationData(props.value.id);
    }
  }, [props.value]);

  useEffect(() => {
    Store.spinner = loadingRule;
  }, [
    loadingRule
  ]);

  const onChangeField = (field, value, selected, handle, fields, values) => {
    const updatedPayload = { ...recipientsPayload };
    for (const category of Object.keys(updatedPayload)) {
      const items = updatedPayload[category];
      for (const item of items) {
        if (
          (category === "dinamic" && item.key === values.recipient) ||
          (category === "static" && item.value === values.recipient)
        ) {
          item.type = values.type;
          break;
        }
      }
    }

    setRecipientsPayload(updatedPayload);
  };

  const saveRecipients = () => {
    let payload = {
      dinamic: recipientsPayload.dinamic.filter((recipient) => recipient.type !== "Default"),
      static: recipientsPayload.static.filter((recipient) => recipient.type !== "Default"),
    };

    Store.spinner = true;
    action
      .execute('post', `${Constants.APIEndpoints.NOTIFICATION}/${props.value.id}/signers`, '', payload)
      .then((res) => {
        Store.spinner = false;
        Store.toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');
        props.loadNotification();
        props.onClose();
      }).catch((error) => {
        console.error(error);
        let messageError = language.ERROR_REQUEST;

        if (error.data?.message === 'E500') {
          messageError = language.ERROR_CODE.E500;
        }

        Store.spinner = false;
        Store.toggleAlert(true, messageError, 'error');
      });
  };

  let col = [
    {
      key: 'recipient',
      label: language.RECIPIENTS,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
      search: false
    },
  ];

  let data = notificationRecipients.map((recipient) => ({
    recipient: `${recipient.recipient}`,
    action: (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-between" style={{ height: '55px' }}>
          <CommonForm
            values={
              {
                recipient: recipient.value,
                type: recipient.type
              }
            }
            fields={[
              {
                col: 12,
                name: 'recipient',
                label: language.RECIPIENTS,
                type: 'text',
                placeholder: language.RECIPIENTS,
                required: true,
                visible: false
              },
              {
                col: 12,
                name: 'type',
                label: language.TYPE,
                type: 'btnGroup',
                options: [
                  { label: language.RECIPIENT, value: 'Default' },
                  { label: 'CC', value: 'CC' },
                  { label: 'CCO', value: 'CCO' }
                ],
                required: true
              }
            ]}
            onChangeField={onChangeField}
            // onSubmit={(v) => saveRecipients(v)}
          />
        </div>
      </React.Fragment>
    ),
  }));

  return (
    <div>
      <CommonTable
        title=""
        index=""
        data={data}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        beforeBar={null}
      />

      <Button
        label={language.SAVE}
        variant="normal"
        style={{ marginTop: '15px' }}
        color="success"
        onClick={() => {
          saveRecipients();
        }}
      />
    </div>
  );
};

export default index;
